import styled from 'styled-components';

export const FormsWrapper = styled.div`
  .desktop-view {
    display: flex;
  }

  .tablet-view {
    display: none;
  }

  .form-item {
    display: grid;
    grid-template-columns: minmax(150px, 340px) minmax(200px, 400px) minmax(200px, 1fr) 200px;
    padding: 10px 0;
  }

  @media only screen and (max-width: 768px) {
    .desktop-view {
      display: none;
    }

    .tablet-view {
      display: flex;
    }
  }
`;
