import moment from 'moment';
import React from 'react';
import { dayOptions, monthOptions, yearOptions } from '../../../helpers/optionData';
import { isDateOfBirthInRange } from '../../../helpers/utils';
import Dropdown from '../dropdown/index';

const DateOfBirthDayMonthYear = ({
  value = {},
  setValue = () => {},
  name = '',
  is_required = false,
  font = {},
  fontFamily = {},
  showRequiredError,
  sub_label = null,
  showValidDataError = false,
  showAgeRangeError = false,
  range = {},
}) => {
  const ageRangeError =
    range?.min && range.max
      ? `Please enter age in between ${range.min} and ${range.max}`
      : range?.min && !range?.max
      ? `Please enter age greater than ${range.min}`
      : !range?.min && range?.max
      ? `Please enter age less than ${range.max}`
      : '';
  return (
    <div className="flex-column w-full">
      {name && (
        <div className="mb-1">
          <span
            className="regular-text lighter-text"
            style={{ fontSize: `${font?.size || 14}px`, color: font?.color || '#6B7280', ...fontFamily }}>
            {name}
          </span>
          {is_required && <span className="required-star ml-1">*</span>}
        </div>
      )}
      <div className="flex w-full col-gap-2 device-scale-component row-gap-4">
        <Dropdown
          options={dayOptions}
          placeholder={'Day'}
          selectedValue={dayOptions.find(option => option.value === value.day)}
          onChange={option => setValue({ ...value, day: option.value })}
          showRequiredError={showRequiredError}
          showError={
            showValidDataError ||
            (showAgeRangeError &&
              value &&
              !isDateOfBirthInRange(moment(`${value.day}/${value.month}/${value.year}`, 'DD/MM/YYYY').unix(), range))
          }
        />
        <Dropdown
          options={monthOptions}
          placeholder={'Month'}
          selectedValue={monthOptions.find(option => option.value === value.month)}
          onChange={option => setValue({ ...value, month: option.value })}
          showRequiredError={showRequiredError}
          showError={
            showValidDataError ||
            (showAgeRangeError &&
              value &&
              !isDateOfBirthInRange(moment(`${value.day}/${value.month}/${value.year}`, 'DD/MM/YYYY').unix(), range))
          }
        />
        <Dropdown
          options={yearOptions}
          placeholder={'Year'}
          selectedValue={yearOptions.find(option => option.value === value.year)}
          onChange={option => setValue({ ...value, year: option.value })}
          showRequiredError={showRequiredError}
          showError={
            showValidDataError ||
            (showAgeRangeError &&
              value &&
              !isDateOfBirthInRange(moment(`${value.day}/${value.month}/${value.year}`, 'DD/MM/YYYY').unix(), range))
          }
        />
      </div>
      {sub_label && (
        <div className="mt-1">
          <span className="regular-text gray-500-text">{sub_label}</span>
        </div>
      )}
      {showAgeRangeError &&
        value &&
        !isDateOfBirthInRange(moment(`${value.day}/${value.month}/${value.year}`, 'DD/MM/YYYY').unix(), range) && (
          <div className="mt-1">
            <span className="regular-text error-text">{ageRangeError}</span>
          </div>
        )}
    </div>
  );
};

export default DateOfBirthDayMonthYear;
