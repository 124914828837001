import moment from 'moment';
import { getItemFromLocalStorage } from './localstorage';

export const capitalize = str => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const formatText = (text, replaceWith = '-') => {
  if (!text) return '';
  return text.toLowerCase().replaceAll('_', replaceWith);
};

export const uppercase = str => {
  if (!str) return '';
  return str.toUpperCase();
};

export const getUsersPermissions = () => {
  const user = getItemFromLocalStorage('user');
  return user?.api_tokens?.permissions;
};

export const emailRegEx = /^[A-Za-z0-9][-A-Z-a-z0-9.!#$%&'*+-=?^_`{|}~\/]+@([-A-Z-a-z0-9]+\.)+[A-Za-z]{2,5}$/;

const fontStyles = {
  THIN: { fontWeight: 100 },
  EXTRALIGHT: { fontWeight: 200 },
  LIGHT: { fontWeight: 300 },
  REGULAR: { fontWeight: 400 },
  MEDIUM: { fontWeight: 500 },
  SEMIBOLD: { fontWeight: 600 },
  BOLD: { fontWeight: 700 },
  EXTRABOLD: { fontWeight: 800 },
  BLACK: { fontWeight: 900 },
  THINITALIC: { fontWeight: 100, fontStyle: 'italic' },
  EXTRALIGHTITALIC: { fontWeight: 200, fontStyle: 'italic' },
  LIGHTITALIC: { fontWeight: 300, fontStyle: 'italic' },
  ITALIC: { fontWeight: 400, fontStyle: 'italic' },
  MEDIUMITALIC: { fontWeight: 500, fontStyle: 'italic' },
  SEMIBOLDITALIC: { fontWeight: 600, fontStyle: 'italic' },
  BOLDITALIC: { fontWeight: 700, fontStyle: 'italic' },
  EXTRABOLDITALIC: { fontWeight: 800, fontStyle: 'italic' },
  BLACKITALIC: { fontWeight: 900, fontStyle: 'italic' },
};

export const getFontFamily = (font_thickness, font_name) => {
  const fontStyle = fontStyles[font_thickness] || fontStyles['REGULAR'];
  return { ...fontStyle, fontFamily: font_name || 'Poppins' };
};

export const getAlignment = alignment => {
  switch (alignment) {
    case 'LEFT':
      return { justifyContent: 'flex-start', textAlign: 'start' };
    case 'RIGHT':
      return { justifyContent: 'flex-end', textAlign: 'end' };
    case 'CENTRE':
      return { justifyContent: 'center', textAlign: 'center' };
    default:
      return { justifyContent: 'center', textAlign: 'unset' };
  }
};

export const isValidDateOfBirth = unix => {
  const dobMoment = moment(unix * 1000);
  return dobMoment.isValid();
};

export const isDateOfBirthInRange = (unix, range) => {
  if ((range?.min || range.max) && unix) {
    const dobMoment = moment(unix * 1000);
    const yearDiff = moment().diff(dobMoment, 'years', false);
    const isGreaterThanMin = range?.min ? yearDiff >= range?.min : true;
    const isLessThanMax = range?.max ? yearDiff <= range?.max : true;
    return isGreaterThanMin && isLessThanMax;
  }
  return true;
};

export const getFormattedAddressData = formSessionData => {
  const addressLexicons = [
    'contact.address.line1',
    'contact.address.line2',
    'contact.address.city',
    'contact.address.state',
    'contact.address.country',
    'contact.address.postcode',
  ];
  const addressData = formSessionData
    .filter(d => addressLexicons.includes(d.lexicon))
    ?.reduce((acc, curr) => {
      acc[curr.lexicon] = curr.submitted_data;
      return acc;
    }, {});
  return (
    addressLexicons
      .map(lexicon => addressData[lexicon])
      .filter(Boolean)
      .join(', ') || ''
  );
};

export const getMarketPreferenceData = formSessionData => {
  const marketPreferenceLexicons = [
    'contact.consent.POST',
    'contact.consent.EMAIL',
    'contact.consent.SMS',
    'contact.consent.PHONE',
    'contact.consent.MESSAGING',
  ];
  const marketPreferenceData = marketPreferenceLexicons.map(data => {
    const selectedLexicon = formSessionData.find(fs => fs.lexicon === data);
    return {
      send_by: data.split('.')[data.split('.').length - 1],
      consent: selectedLexicon ? Boolean(selectedLexicon.submitted_data) : false,
    };
  });

  return marketPreferenceData;
};
export const getFormattedBillingAddressData = address => {
  const addressLexicons = ['line1', 'line2', 'city', 'state', 'country', 'postcode'];
  const addressData = Object.keys(address)
    .filter(d => addressLexicons.includes(d))
    ?.reduce((acc, curr) => {
      acc[curr] = curr.submitted_data || address[curr];
      return acc;
    }, {});

  return (
    addressLexicons
      .map(lexicon => addressData[lexicon])
      .filter(Boolean)
      .join(', ') || ''
  );
};

export const getDataFromSessionData = (formSessionData, selectedAddress, lexicon) => {
  let data = formSessionData.find(d => d.lexicon === lexicon)?.submitted_data;
  if (lexicon === 'contact.formatted_address') {
    data = getFormattedAddressData(formSessionData);
  } else if (lexicon === 'contact.address.country_code') {
    data = selectedAddress?.country_code;
  } else if (lexicon === 'contact.consent') {
    data = getMarketPreferenceData(formSessionData);
  }
  return data || '';
};

export const getUserBillingAddress = (selectedBillingAddress, showBillingAddress, lexicon) => {
  let data = '';
  const { id, address } = selectedBillingAddress || {};
  const billingAddressHidden = selectedBillingAddress ? showBillingAddress[id] : false;
  if (billingAddressHidden) {
    if (lexicon === 'formatted_address') {
      data = getFormattedBillingAddressData(address);
    } else if (lexicon === 'country_code') {
      data = selectedBillingAddress?.country_code;
    } else {
      const addressKey = Object.keys(address).find(d => d === lexicon);
      data = address[addressKey] || '';
    }
    return data;
  }
  return data || '';
};

export const getUserData = (formSessionData, selectedAddress, selectedBillingAddress, showBillingAddress) => {
  const user = {
    title: getDataFromSessionData(formSessionData, selectedAddress, 'contact.title.name'),
    forename: getDataFromSessionData(formSessionData, selectedAddress, 'contact.forename'),
    surname: getDataFromSessionData(formSessionData, selectedAddress, 'contact.surname'),
    gender: getDataFromSessionData(formSessionData, selectedAddress, 'contact.gender.name'),
    profession: getDataFromSessionData(formSessionData, selectedAddress, 'contact.profession.name'),
    date_of_birth: getDataFromSessionData(formSessionData, selectedAddress, 'contact.date_of_birth')
      ? parseInt(getDataFromSessionData(formSessionData, selectedAddress, 'contact.date_of_birth'))
      : null,
    emails: [
      {
        value: getDataFromSessionData(formSessionData, selectedAddress, 'contact.email'),
        is_primary: true,
        email_type: 'PERSONAL',
      },
    ],
    phones: [
      {
        contact_type: 'MOBILE',
        contact_value: getDataFromSessionData(formSessionData, selectedAddress, 'contact.mobile')
          .replace('+44', '')
          .replace('+1', ''),
        is_primary: true,
        phone_type: 'PERSONAL',
        country_code: getDataFromSessionData(formSessionData, selectedAddress, 'contact.mobile')?.startsWith('+1')
          ? '+1'
          : '+44',
      },
      {
        contact_type: 'LANDLINE',
        contact_value: getDataFromSessionData(formSessionData, selectedAddress, 'contact.landline')
          .replace('+44', '')
          .replace('+1', ''),
        is_primary: true,
        phone_type: 'PERSONAL',
        country_code: getDataFromSessionData(formSessionData, selectedAddress, 'contact.landline')?.startsWith('+1')
          ? '+1'
          : '+44',
      },
    ].filter(p => p.contact_value),
    addresses: [
      {
        formatted_address: getDataFromSessionData(formSessionData, selectedAddress, 'contact.formatted_address'),
        is_primary: true,
        line1: getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.line1'),
        line2: getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.line2'),
        city: getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.city'),
        postcode: getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.postcode'),
        state: getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.state'),
        country_code: getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.country_code') || 'US',
        type: 'RESIDENTIAL',
        is_postal: true,
        is_billing: false,
        is_residential: true,
      },
      {
        formatted_address:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'formatted_address') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.formatted_address'),
        is_primary: true,
        line1:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'line1') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.line1'),
        line2:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'line2') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.line2'),
        city:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'city') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.city'),
        postcode:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'cpostcode') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.postcode'),
        state:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'state') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.state'),
        country_code:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'country_code') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.country_code') ||
          'US',
        type: 'BILLING',
        is_postal: false,
        is_billing: true,
        is_residential: false,
      },
    ].filter(a => a.formatted_address),
    marketing_preferences: getDataFromSessionData(formSessionData, selectedAddress, 'contact.consent'),
    call_time: [],
  };
  return user;
};

export const validateCharacterLimit = (character_limit, value) => {
  if (character_limit?.min && character_limit?.max) {
    return value.length >= character_limit.min && value.length <= character_limit.max;
  } else if (character_limit?.min && !character_limit?.max) {
    return value.length >= character_limit.min;
  } else if (!character_limit?.min && character_limit?.max) {
    return value.length <= character_limit.max;
  }
  return true;
};

export const characterLimitRangeError = character_limit => {
  return character_limit?.min && character_limit.max
    ? `Please enter characters in between ${character_limit.min} and ${character_limit.max}`
    : character_limit?.min && !character_limit?.max
    ? `Please enter characters greater than ${character_limit.min}`
    : !character_limit?.min && character_limit?.max
    ? `Please enter characters less than ${character_limit.max}`
    : '';
};

export const isJson = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const getUserInitials = user => {
  const { forename, surname, name } = user;
  const userName = name?.split(' ') || null;

  return userName
    ? `${userName?.[0]?.charAt(0)?.toUpperCase() || ''}${userName?.[1]?.charAt(0)?.toUpperCase() || ''}`
    : forename || surname
    ? `${forename?.charAt(0)?.toUpperCase() || ''}${surname?.charAt(0)?.toUpperCase() || ''}`
    : '';
};

export const countMatchesInRegex = (regex, str) => {
  return ((str || '').match(regex) || []).length;
};
