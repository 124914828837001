import React, { useContext } from 'react';
import { OrganisationContext } from '../context/organisationContext';
import CommonPopup from './common-popup';

const initModal = {
  type: null,
  content: {},
};

const LeavePage = () => {
  const { setModal, modal } = useContext(OrganisationContext);

  const { onConfirm = () => {} } = modal.content || {};

  const onConfirmAction = async () => {
    onConfirm();
    setModal(initModal);
  };

  return (
    <CommonPopup
      popupTitle="Are you sure you want to leave this page?"
      popupSubTitle="Leaving a form before submitting will result in the loss of any inputted information."
      confirmButtonProps={{
        label: 'Yes, leave',
      }}
      cancelButtonProps={{ label: 'No, go back' }}
      onCancel={() => setModal(initModal)}
      onConfirm={onConfirmAction}
    />
  );
};

export default LeavePage;
