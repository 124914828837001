import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '../../common/tooltip/tooltip';

const ProgressBar = ({ formPanel, currentStep, totalSteps, changeSelectedPanel }) => {
  const progressBarPanelDetails = (panel, index) => {
    return (
      <div>
        <span className="regular-text font-16 tooltip-panel-details">
          {index + 1}. {panel.name}
        </span>
      </div>
    );
  };

  return (
    <ProgressBarWrapper className={`flex-column items-center justify-center`}>
      <div className="flex items-center progress-bar relative">
        <div className="progress-bar-line absolute" />
        {formPanel?.map((panel, index) => (
          <div
            className="flex items-center"
            style={{
              width: `${100 / totalSteps}%`,
              justifyContent: index === 0 ? 'flex-start' : index === totalSteps - 1 ? 'flex-end' : 'center',
            }}>
            <Tooltip
              className="progress-bar-tooltip-content"
              content={progressBarPanelDetails(panel, index)}
              placement="top">
              <div className={`cursor dot ${currentStep === index && 'current-step'}`}></div>
            </Tooltip>
          </div>
        ))}
      </div>
    </ProgressBarWrapper>
  );
};

const ProgressBarWrapper = styled('div')`
  width: 90%;
  margin: 24px auto;

  .progress-bar {
    width: 90%;
    min-height: 23px;

    .progress-bar-line {
      width: 100%;
      height: 2px;
      background: #ffffff;
    }

    .dot {
      width: 13px;
      height: 13px;
      background: #ffffff;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      z-index: 4;

      &:hover {
        width: 23px;
        height: 23px;
        background: ${({ theme }) => theme.colors.ZenPurple};
        border-color: #ffffff;
        box-shadow: inset 0px 0px 0px 8px #ffffff;
      }
    }

    .current-step {
      width: 23px;
      height: 23px;
      background: ${({ theme }) => theme.colors.ZenPurple};
      border-color: #ffffff;
      box-shadow: inset 0px 0px 0px 3px #ffffff;

      &:hover {
        box-shadow: inset 0px 0px 0px 3px #ffffff;
      }
    }

    .progress-bar-tooltip-content {
      z-index: 4;
    }
    .tooltip-content {
      background-color: ${({ theme }) => theme.colors.ZenDefaultSecondary};
      border-radius: 12px;
      padding: 6px 20px;

      .tooltip-panel-details {
        color: ${({ theme }) => theme.colors.ZenPurple};
      }
    }
  }
`;

export default ProgressBar;
