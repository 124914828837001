import React, { useEffect, useRef, useState } from 'react';
import ArrowDownIcon from '../../../assets/images/arrow-down.svg';
import Loading from '../../../assets/images/loading.svg';
import './style.scss';

const DropDown = ({
  options,
  selected,
  setSelected,
  placeholder,
  isSearchable,
  search,
  setSearch,
  isPaged,
  hasMore,
  fetchData,
  size = 'medium',
  description,
  icon = false,
  getIcon = () => {},
  hideOptions = false,
  className = '',
  removeSearchOnBlur = false,
}) => {
  const ref = useRef();
  const loader = useRef(null);

  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);

  const itemHeight = description ? '56' : '40';

  const onBlur = e => {
    e.preventDefault();
    if (!e.currentTarget.contains(e.relatedTarget)) {
      setShow(false);
      setEdit(false);
      if (removeSearchOnBlur) {
        setSearch('');
      }
    }
  };

  const onClick = (edit, show) => {
    setEdit(edit);
    setShow(show);
  };

  const handleObserver = entities => {
    const target = entities[0];
    if (target.isIntersecting) {
      if (hasMore) fetchData();
    }
  };

  useEffect(() => {
    if (!isPaged) return;
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
    return () => {
      if (loader.current) {
        observer.unobserve(loader.current);
      }
    };
  }, [loader.current, isPaged]);

  const select = s => {
    setEdit(false);
    setShow(false);
    setSelected(s);
    if (isSearchable) setSearch('');
  };

  return (
    <div
      className={`flex mc-select relative no-select mc-select ${className}`}
      id="of-id"
      onBlur={onBlur}
      tabIndex={0}
      style={{}}>
      <div
        className={`flex items-center justify-between organisations-border mc-border flex-1 cursor pl-4 w-full ${
          show && 'dropdown-open'
        }`}
        ref={ref}
        onClick={e => {
          e.stopPropagation();
          isSearchable ? onClick(true, true) : setShow(!show);
        }}>
        {isSearchable && edit ? (
          <div className="flex items-center" onClick={() => setShow(true)}>
            <input
              className="w-full org-name-input border-none regular-text"
              autoFocus
              value={search}
              onChange={e => setSearch(e.target.value)}
            />
          </div>
        ) : selected ? (
          <label className={`regular-text selected-name flex-1 flex ${size === 'large' && 'font-16'}`}>
            {icon && (
              <span className="flex items-center">
                <img className="selected-icon mr-2" src={getIcon(selected)} alt="icon" />
              </span>
            )}
            {selected.name}
          </label>
        ) : (
          <label className={`regular-text o03  ${size === 'large' && 'font-16'}`}>{placeholder}</label>
        )}
        <div className="input-arrow" onClick={() => onClick(false, !show)}>
          <img alt="icon" className={`dropdown-icon ${show && 'open'} cursor`} src={ArrowDownIcon} />
        </div>
      </div>
      {/* hideOptions helps in hiding options with display none so it doesn't effect when dropdown is inside draggable container */}
      <div
        className="mc-options absolute flex-column card px-2"
        style={{
          height: show ? options?.length * itemHeight + 16 : 0,
          display: hideOptions ? (show ? 'flex' : 'none') : 'flex',
        }}>
        <div className="flex-column py-2">
          {options?.map((option, i) => (
            <div
              key={option.id}
              className="flex items-center px-2 cursor mc-option-item"
              onClick={e => {
                e.stopPropagation();
                select(option);
              }}
              style={{ height: `${itemHeight}px` }}>
              <div className="flex-column">
                <label className="regular-text flex option-name">
                  {icon && (
                    <span className="flex items-center">
                      <img className="option-icon mr-2" src={getIcon(option)} alt="icon" />
                    </span>
                  )}
                  {option.name}
                </label>
                {description && (
                  <label
                    className="regular-text font-12 light-purple-text"
                    style={{ width: `${ref.current?.offsetWidth - 30}px` }}>
                    {option.description}
                  </label>
                )}
              </div>
            </div>
          ))}
          {isPaged && hasMore && (
            <div ref={loader} className="item flex items-center justify-center my-2">
              <img src={Loading} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DropDown;
