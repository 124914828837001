import { createSlice } from '@reduxjs/toolkit';
import Axios from 'axios';
import api from '../../store/services';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: JSON.parse(localStorage.getItem('user')),
    userDetails: JSON.parse(localStorage.getItem('user'))?.user,
    organisations: JSON.parse(localStorage.getItem('user'))?.organisations,
    organisation: JSON.parse(localStorage.getItem('user'))?.organisations?.[0]?.external_id,
    loading: false,
    error: {},
    forgotPasswordStep: 'EMAIL',
  },
  reducers: {
    setLoading: (state, { payload }) => {
      state.loading = payload.loading;
      state.loadingType = payload.loadingType;
    },
    setError: (state, { payload }) => {
      state.loading = false;
      state.error = payload.error;
      state.errorType = payload.errorType;
    },
    setUser: (state, { payload }) => {
      state.user = payload;
      state.userDetails = payload?.user;
      state.organisations = payload?.organisations;
      state.organisation = payload?.organisations?.[0]?.external_id;
    },
    setForgotPasswordStep: (state, { payload }) => {
      state.forgotPasswordStep = payload;
    },
    setProfile: (state, { payload }) => {
      state.profileData = payload;
    },
  },
});

export const loginUser = payload => async dispatch => {
  dispatch(setLoading({ loading: true, loadingType: 'LOGIN' }));
  try {
    const { data } = await api.get(`/api/user/authenticate`, payload);
    dispatch(setUser(data));
    dispatch(setLoading({ loading: false, loadingType: '' }));
  } catch (error) {
    dispatch(setError({ error: { ...error.response.data }, errorType: 'LOGIN' }));
  }
};

export const sendOtpEmail = payload => async dispatch => {
  dispatch(setLoading({ loading: true, loadingType: 'FORGOT_PASSWORD' }));
  try {
    await api.get(`/api/passwords/otp`, payload);
    dispatch(setForgotPasswordStep('OTP'));
    dispatch(setLoading({ loading: false, loadingType: '' }));
  } catch (error) {
    dispatch(setError({ error: { ...error.response.data }, errorType: 'FORGOT_PASSWORD' }));
  }
};

export const validateOtp = payload => async dispatch => {
  dispatch(setLoading({ loading: true, loadingType: 'FORGOT_PASSWORD' }));
  try {
    await api.get(`/api/otp/validate`, payload);
    dispatch(setForgotPasswordStep('PASSWORD_RESET'));
    dispatch(setLoading({ loading: false, loadingType: '' }));
  } catch (error) {
    dispatch(setError({ error: { ...error.response.data }, errorType: 'FORGOT_PASSWORD' }));
  }
};

export const changePassword = payload => async dispatch => {
  dispatch(setLoading({ loading: true, loadingType: 'FORGOT_PASSWORD' }));
  try {
    await api.get(`/api/adminusers/passwords`, payload, payload?.header);
    dispatch(setForgotPasswordStep('PASSWORD_CHANGED'));
    dispatch(setLoading({ loading: false, loadingType: '' }));
  } catch (error) {
    dispatch(setError({ error: { ...error.response.data }, errorType: 'FORGOT_PASSWORD' }));
  }
};

export const getProfileDetails = payload => async dispatch => {
  try {
    const { id } = payload;
    const { data } = await api.get(`/users/${id}`);
    dispatch(setProfile(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateProfileData = payload => async dispatch => {
  try {
    const { request } = payload;
    const { data } = await api.put(`/users/profile`, request);
    Promise.resolve(data);
  } catch (error) {}
};

export const getPasswordRequirements = token => async dispatch => {
  return Axios.get(`${process.env.REACT_APP_API_URL}/users/password_requirements`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then(({ data }) => {
      return Promise.resolve(data);
    })
    .catch(error => {
      return Promise.reject(error);
    });
};

export const { setUser, setLoading, setError, setForgotPasswordStep, setProfile } = userSlice.actions;
export default userSlice.reducer;
