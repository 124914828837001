import { configureStore } from '@reduxjs/toolkit';
import fileuploadSlice from './features/fileuploadSlice';
import formSlice from './features/formSlice';
import reloadSlice from './features/reloadSlice';
import toastSlice from './features/toastSlice';
import userSlice from './features/userSlice';

export default configureStore({
  reducer: {
    reload: reloadSlice,
    toast: toastSlice,
    user: userSlice,
    forms: formSlice,
    fileUpload: fileuploadSlice,
  },
  devTools: process.env.REACT_APP_STAGE === 'development',
});
