import styled from 'styled-components';

export const ForgotPasswordWrapper = styled.div`
  .forgot-password-page {
    width: 100%;
    height: 100vh;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.colors.color};
    background-color: ${props => props.theme.colors.backgroundColor};

    .back-icon-container {
      top: 23%;
      left: 100px;
      background: #ffffff;
      box-shadow: 0px 4px 20px rgba(103, 103, 103, 0.08);
      border-radius: 16px;
      width: 40px;
      height: 40px;

      .back-icon {
        path {
          stroke: ${({ theme }) => theme.colors.ZenPurple};
          stroke-width: 2px;
        }
      }
    }

    .logo {
      width: 152px;
      height: 82px;
    }

    .text-logo {
      width: 228px;
      height: 48px;
    }

    .fp-content {
      width: 488px;
      .subtitle {
        max-width: 360px;
        text-align: center;
      }

      .phone-input {
        padding-left: 36px;
      }

      .otp-content {
        margin-top: 24px;

        .otp-input {
          margin: 0px 4px;

          .input-style {
            width: 50px;
            height: 50px;
            border: unset;
            text-align: center;
            background: #ffffff;
            font-size: 16px;
            color: #053149;
            font-family: Poppins Regular;
            border: 1px solid #d0d5dd;
            border-radius: 12px;

            &:focus-visible {
              outline: 0px;
            }

            &::-webkit-input-placeholder {
              opacity: 0.3;
              color: #053149;
            }
          }

          .error-style {
            border: 1px solid #ff5b5b;
          }
        }
      }

      .password-requirement-tooltip {
        position: relative;
        .password-requirement-tooltip-details {
          position: relative;
        }
        .error-icon {
          path {
            fill: #ff406e;
          }
        }
        .tooltip-content {
          height: fit-content;
          width: fit-content;
          max-width: fit-content;
          z-index: 10;
          margin: 4px 0;
          box-shadow: 2px 8px 30px rgb(5 49 73 / 10%);
          padding: 12px 16px;

          &::before {
            width: 0px;
            height: 0px;
          }

          #arrow,
          #arrow::before {
            position: absolute;
            width: 16px;
            height: 16px;
            background: inherit;
            background: #ffffff;
          }

          #arrow {
            visibility: hidden;
          }

          #arrow::before {
            visibility: visible;
            content: '';
            transform: rotate(45deg);
          }
        }

        .tooltip-content[data-placement^='right'] > #arrow {
          left: -6px;
        }
      }

      .input-label {
        align-self: flex-start;
        font-family: Roboto Light;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
        margin-bottom: 5px;
        margin-top: 20px;
      }

      input {
        outline: 0;
        font-size: 16px;
        height: 48px;
        background: #ffffff;
        border: 1px solid #eceef3;
        border-radius: 16px;
        padding: 0 16px;
        font-family: Poppins Regular;
        font-size: 16px;
        color: #020819;
        width: 100%;

        &:focus {
          border-color: ${({ theme }) => theme.colors.ZenPurple};
        }
      }

      button {
        margin-top: 40px;
        margin-bottom: 24px;
        height: 48px;
        width: 100%;
        border-radius: 16px;
        background-color: #32e88b;
        border: none;
        outline: 0;
        cursor: pointer;
        font-family: Poppins Medium;
        font-style: normal;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
      }

      .error-input {
        border: 1px solid #ff406e;

        &:focus {
          border-color: #ff406e;
        }
      }
    }

    .show-password {
      position: absolute;
      width: 24px;
      height: 24px;
      right: 16px;
      top: 12px;
      cursor: pointer;
    }

    .otp-error {
      width: 100%;
      background: #ff406e;
      border-radius: 8px;
      padding: 8px;

      img {
        width: 16px;
        height: 16px;
      }
    }

    .password-error {
      width: 100%;
      background: #ff406e;
      border-radius: 8px;

      img {
        width: 16px;
        height: 16px;
      }
    }
  }
`;
