import styled from 'styled-components';
import profileBase from '../../assets/images/profile/base.svg';

export const ProfileWrapper = styled.div`
  .profile-base-wrapper {
    background-image: url(${profileBase});
    height: 167px;
    padding: 32px 48px;
    background-size: cover;
  }

  .plus-icon-background {
    background: ${({ theme }) => theme?.colors?.white};
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }

  .updateAction {
    position: relative;
    right: 55px;
    top: 70px;
  }
  .desktop-status {
    display: flex;
  }
  .mobile-status {
    display: none;
  }
  .user-details {
    top: -50px;
  }
  .img-container {
    min-width: 140px;
    max-width: 140px;
    min-height: 140px;
    max-height: 140px;
    outline: 4px solid white;
  }
  .empty-img-content {
    background: ${({ theme }) => theme?.colors?.ZenGray3};
    .add-img-btn {
      display: none;
      height: 24px;
      width: 24px;
      background: ${({ theme }) => theme.colors.ZenDefaultSecondary};
    }

    &.on-edit {
      background: #16192c33;
      opacity: 1;

      .empty-img {
        display: none;
      }

      .add-img-btn {
        display: flex;
      }
    }
  }

  .img-data-wrapper {
    min-width: 140px;
    max-width: 140px;
    min-height: 140px;
    max-height: 140px;
    outline: 4px solid white;

    .delete-btn {
      display: none;
    }
    .delete-btn-wrapper {
      height: 24px;
      width: 24px;
      background: #ffff;
      display: none;
    }
    &.on-edit {
      .delete-btn {
        display: flex;
        inset: 0;
        background: #16192c33;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
      }
      .delete-btn-wrapper {
        display: flex;
        background: #ffffff;
      }
    }
  }

  .back-icon-container {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(103, 103, 103, 0.08);
    width: 40px;
    height: 40px;

    .back-icon {
      path {
        stroke: ${({ theme }) => theme.colors.ZenPurple};
        stroke-width: 2px;
      }
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 192px);
  }
  .details-box-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 40px;

    .details-box {
      border: 1px solid ${({ theme }) => theme.colors.border};
      width: calc(100% / 3);
    }
  }
  .desktop-edit-btn {
    display: flex;
  }
  .mobile-edit-btn {
    display: none;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 768px) {
    .profile-base-wrapper {
      background-image: url(${profileBase});
      height: 167px;
      object-fit: cover;
      padding: 32px 16px;
      background-repeat: no-repeat;
      width: 100%;
    }
    .container {
      display: flex;
      flex-direction: column;
      width: calc(100% - 32px);
    }
    .details-box-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      row-gap: 24px;

      .details-box {
        border: 1px solid ${({ theme }) => theme.colors.border};
        width: 100%;
      }
    }
    .desktop-edit-btn {
      display: none;
    }
    .mobile-edit-btn {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      margin-bottom: 16px;
    }
    .desktop-status {
      display: none;
    }
    .mobile-status {
      display: flex;
    }
  }
`;
