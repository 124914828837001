import { createGlobalStyle } from 'styled-components';
import PoppinsBlack from '../assets/fonts/Poppins-Black.ttf';
import PoppinsBold from '../assets/fonts/Poppins-Bold.ttf';
import PoppinsLight from '../assets/fonts/Poppins-Light.ttf';
import PoppinsMedium from '../assets/fonts/Poppins-Medium.ttf';
import PoppinsRegular from '../assets/fonts/Poppins-Regular.ttf';
import PoppinsSemibold from '../assets/fonts/Poppins-SemiBold.ttf';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  @font-face {
    font-family: 'Poppins Light';
    src: local('Poppins Light'), url(${PoppinsLight}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins Regular';
    src: local('Poppins Regular'), url(${PoppinsRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins Medium';
    src: local('Poppins Medium'), url(${PoppinsMedium}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins Semibold';
    src: local('Poppins Semibold'), url(${PoppinsSemibold}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins Bold';
    src: local('Poppins Bold'), url(${PoppinsBold}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins Black';
    src: local('Poppins Black'), url(${PoppinsBlack}) format('truetype');
  }

  * {
    box-sizing: border-box;
  }
`;

export default GlobalStyle;
