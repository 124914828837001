import styled from 'styled-components';

export const MultiFactorAuthWrapper = styled.div`
  .mfa-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    color: ${props => props.theme.colors.color};
    background-color: ${props => props.theme.colors.backgroundColor};

    .mfa {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100vh;
      justify-content: center;

      .back-icon-container {
        top: 23%;
        left: 100px;
        background: #ffffff;
        box-shadow: 0px 4px 20px rgba(103, 103, 103, 0.08);
        border-radius: 16px;
        width: 40px;
        height: 40px;

        .back-icon {
          path {
            stroke: ${({ theme }) => theme.colors.ZenPurple};
            stroke-width: 2px;
          }
        }
      }

      .mfa-form {
        display: flex;
        flex-direction: column;
        align-items: center;

        .logo {
          width: 152px;
          height: 80px;
        }

        .text-logo {
          width: 228px;
          height: 48px;
        }

        .subtitle {
          max-width: 360px;
          text-align: center;
        }

        .otp-content {
          margin-top: 40px;

          .otp-input {
            margin: 0px 4px;

            .input-style {
              width: 50px;
              height: 50px;
              border: unset;
              text-align: center;
              background: #ffffff;
              // box-shadow: 5px 5px 30px rgb(5 49 73 / 10%);
              font-size: 16px;
              color: #053149;
              font-family: Poppins Regular;
              border: 1px solid #d0d5dd;
              border-radius: 12px;

              &:focus-visible {
                outline: 0px;
                border: 1px solid ${({ theme }) => theme.colors.ZenPurple};
              }

              &::-webkit-input-placeholder {
                opacity: 0.3;
                color: #053149;
              }
            }

            .error-style {
              border: 1px solid #ff5b5b;

              &:focus-visible {
                border: 1px solid #ff5b5b;
              }
            }
          }
        }

        .re-send-icon {
          path {
            fill: rgb(137, 39, 239);
          }
        }

        .otp-error {
          width: 340px;
          background: #ff406e;
          border-radius: 8px;
          padding: 4px;

          img {
            width: 16px;
            height: 16px;
          }
        }

        .error-msg {
          display: flex;
          flex-direction: row;
          align-items: center;
          position: absolute;
          top: 50px;

          .error-icon {
            min-width: 12px;
            min-height: 12px;
            width: 12px;
            height: 12px;
            margin: 0px;
          }

          label {
            font-family: Poppins Medium;
            font-style: normal;
            font-size: 12px;
            color: #d93e38;
            margin-left: 5px;
          }
        }

        .error-message {
          display: flex;
          align-items: center;
          background: #ff406e;
          border-radius: 8px;
          padding: 4px 8px;

          .error-icon {
            path {
              stroke: #ffffff;
            }
          }
        }
      }
    }
  }
`;
