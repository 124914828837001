import React, { useEffect, useState } from 'react';
import { TooltipStateless } from './tooltip-stateless';

export const Tooltip = props => {
  const [isOpen, setIsOpen] = useState(false);
  let timeout;

  const { shouldOpen = true, isAlwaysOpen = false } = props;

  useEffect(() => {
    return () => clearTimeout(timeout);
  }, []);

  const handleMouseEnter = () => {
    if (isOpen) return;
    timeout = setTimeout(() => setIsOpen(true), props.showDelay);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };

  return (
    <TooltipStateless
      className={props.className}
      content={props.content}
      isOpen={isOpen || isAlwaysOpen}
      offset={props.offset}
      onMouseEnter={!isAlwaysOpen ? (shouldOpen ? handleMouseEnter : () => {}) : () => {}}
      onMouseLeave={!isAlwaysOpen ? handleMouseLeave : () => {}}
      placement={props.placement}
      style={props.style}>
      {props.children}
    </TooltipStateless>
  );
};
