import React from 'react';
import styled from 'styled-components';
import { characterLimitRangeError, validateCharacterLimit } from '../../../helpers/utils';

const InputElement = ({
  name,
  is_required,
  value,
  defaultValue,
  onChange = () => {},
  label,
  type,
  className = '',
  font = null,
  fontFamily,
  is_autofill,
  autoFillValue,
  showRequiredError,
  showRequireConfirmationError,
  sub_label = null,
  character_limit = null,
  showCharacterLimitError = false,
}) => {
  return (
    <InputElementWrapper className={className}>
      <div className="mb-1">
        <span
          className="regular-text lighter-text"
          style={{ fontSize: `${font?.size || 14}px`, color: font?.color || '#6B7280', ...fontFamily }}>
          {name}
        </span>
        {is_required && <span className="required-star ml-1">*</span>}
      </div>
      {type === 'TEXTAREA' ? (
        <textarea
          className={`regular-text font-16 px-4 py-2 w-full input-element textarea-element ${
            ((showRequiredError && !value) || showRequireConfirmationError) && 'input-error'
          }`}
          defaultValue={defaultValue}
          value={is_autofill ? autoFillValue : value}
          disabled={is_autofill}
          placeholder={label}
          onChange={({ target: { value } }) => onChange(value)}
        />
      ) : (
        <input
          className={`regular-text font-16 px-4 w-full input-element ${
            ((showRequiredError && !value) || showRequireConfirmationError) && 'input-error'
          }`}
          defaultValue={defaultValue}
          value={is_autofill ? autoFillValue : value}
          disabled={is_autofill}
          type={type === 'NUMERIC' ? 'number' : 'text'}
          placeholder={label}
          onChange={({ target: { value } }) => onChange(value)}
        />
      )}
      {sub_label && (
        <div className="mt-1">
          <span className="regular-text gray-500-text">{sub_label}</span>
        </div>
      )}
      {showCharacterLimitError &&
        !validateCharacterLimit(character_limit, (is_autofill ? autoFillValue : value) || '') && (
          <div className="mt-1">
            <span className="regular-text error-text" style={{ fontSize: `${font.size || 14}px`, ...fontFamily }}>
              {characterLimitRangeError(character_limit)}
            </span>
          </div>
        )}
    </InputElementWrapper>
  );
};

const InputElementWrapper = styled('div')`
  .input-element {
    height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    border-radius: 16px;
    outline: none;

    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.ZenPurpleSecondary};
    }

    &:focus {
      border: 1px solid ${({ theme }) => theme.colors.ZenPurple};
    }

    &::placeholder {
      opacity: 0.3;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .textarea-element {
    min-height: 96px;
    resize: none;
    border-radius: 18px;
    border: 1px solid ${({ theme }) => theme.colors.ZenGraySecondary};
  }

  .input-error {
    border-color: ${({ theme }) => theme.colors.ZenNegative};

    &:hover {
      border-color: ${({ theme }) => theme.colors.ZenNegative};
    }

    &:focus {
      border-color: ${({ theme }) => theme.colors.ZenNegative};
    }
  }
`;

export default InputElement;
