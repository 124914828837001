import styled, { createGlobalStyle } from 'styled-components';

export const PopoverAnimationGlobalStyle = createGlobalStyle`
.tp-ds__popover-enter {
  opacity: 0;
}
.tp-ds__popover-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.tp-ds__popover-exit {
  opacity: 1;
}
.tp-ds__popover-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
`;

export const Content = styled.div`
  pointer-events: none;
  color: white;
  background-color: rgb(49, 49, 54);
  display: flex;
  max-width: 250px;
  padding: 10px;
  color: #0531499c;
  border-radius: 8px;
  justify-content: center;
  background: #ffffff;
  bottom: 8px !important;
  box-shadow: 1px 6px 17px 9px rgb(5 49 73 / 5%);
  height: fit-content;

  #arrow,
  #arrow::before {
    position: absolute;
    width: 16px;
    height: 16px;
    background: inherit;
    background: #ffffff;
  }

  #arrow {
    visibility: hidden;
  }
`;
