import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import ReloadIcon from '../assets/images/reload-page-icon.svg';
import Button from '../components/Elements/button/button';
import { init } from '../store/features/reloadSlice';

const ReloadPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <ReloadPageWrapper>
      <div className="flex flex-column items-center card px-12 py-8 refresh-view">
        <label className="bold-text font-24 modal-title">{t('NEW_VERSION_AVAILABLE')}</label>
        <label className="regular-text lighter-text text-center modal-description mt-2 w-75">
          {t('NEW_VERSION_DESCRIPTION')}
        </label>
        <div className="reload-image mt-8">
          <img className="w-full h-full flex-1" src={ReloadIcon} />
        </div>
        <Button
          label={t('REFRESH')}
          className="primary mt-11"
          borderRadius="16px"
          width="100%"
          labelClassName="medium-text font-16"
          size="large"
          onClick={() => {
            dispatch(init());
            window.location.reload();
          }}
        />
      </div>
    </ReloadPageWrapper>
  );
};

const ReloadPageWrapper = styled.div`
  .reload-image {
    width: 126px;
    height: 140px;
  }
  .modal-title {
    letter-spacing: 0.46px;
    line-height: 36px;
  }
  .refresh-view {
    width: 456px;
    @media (max-width: 425px) {
      width: 400px;
      .modal-description {
        width: 90%;
      }
    }
    @media (max-width: 375px) {
      width: 345px;
      padding: 32px 24px;
    }
    @media (max-width: 320px) {
      width: 290px;
      padding: 32px 24px;
      .modal-title {
        font-size: 20px;
      }
      .modal-description {
        width: 100%;
        font-size: 12px;
      }
    }
  }
`;

export default ReloadPage;
