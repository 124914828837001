const themeHelper = {
  down: breakpoint => `@media screen and (max-width: ${breakpoint})`,
  up: breakpoint => `@media screen and (min-width: ${breakpoint})`,
  breakpoints: {
    lg: '1024px',
    xl: '1920px',
  },
};

export const light = {
  ...themeHelper,
  colors: {
    white: '#FFFFFF',
    primary: '#4851f4',
    backgroundColor: '#F5F9FC',
    nav: '#f8f8f8',
    border: '#E2E7EF',
    text: '#202224',
    sidebarBackground: '#FCFEFF',

    ZenDefault: '#2B2B7C',
    ZenPurple: '#8927EF',
    ZenPink: '#EF7ABD',
    ZenGray: '#A0A0A0',

    ZenDefaultSecondary: '#DBDBFF',
    ZenPurpleSecondary: '#E8D1FF',
    ZenPinkSecondary: '#FFD8EE',
    ZenGraySecondary: '#D0D5DD',
    ZenPurpleSecondary2: '#F4EBFF',
    ZenPurpleSecondary3: '#7520CD',

    RegularText: '#16192C',
    LighterText: '#6B7280',
    ZenGray2: '#ECEEF3',
    ZenLightGray: '#F2F4F7',
    Gray2: '#73868C',
    LightGrey: '#D2E5ED',
    Gray500: '#667085',
    ZenGray3: '#F4F7F9',

    ZenPositiveText: '#1E7021',
    ZenPositive: '#29CC97',
    ZenPositive_O5: '#29cc9780',
    ZenPositiveSecondary: '#DAFFF3',

    ZenOrangeText: '#F27C0E',
    ZenOrange: '#F8B133',
    ZenOrangeSecondary: '#FFFAE1',

    ZenNegative: '#FF406E',
    ZenNegativeSecondary: '#FFD5DF',
    ZenNegativeSecondary_O3: 'rgba(255, 212, 223, 0.3)',

    ZenBlue: '#7BB2FD',

    ZenRed: '#FF4040',
    ZenRedSecondary: '#FFE7E7',
    ZenPinkSecondary2: '#FFECF7',
    ZenPink2: '#FF40A7',
    ZenPurpleSecondary2: '#F2E4FF',

    ZenGray2Secondary: '#EBF0F6',

    customStatusBackground: '#DBECFF',
    customStatusText: '#43548E',

    additionalRed: '#FFF0F0',
    additionalGreen: '#E6FAF3',
    additionalYellow: '#FAF4E7',
    ZenBlueSecondary: '#DEECFF',
  },
};

export const dark = {
  ...themeHelper,
  colors: {
    primary: '#4851f4',
    backgroundColor: '#222',
    nav: '#27282b',
    border: '#303236',
    text: '#f8f8f8',
    sidebarBackground: '#FCFEFF',

    ZenDefault: '#2B2B7C',
    ZenPurple: '#8927EF',
    zenPink: '#EF7ABD',
    zenGray: '#A0A0A0',
    ZenGray3: '#F4F7F9',

    ZenDefaultSecondary: '#DBDBFF',
    ZenPurpleSecondary: '#E8D1FF',
    ZenPinkSecondary: '#FFD8EE',
    ZenGraySecondary: '#D0D5DD',
    ZenPurpleSecondary2: '#F4EBFF',

    RegularText: '#16192C',
    LighterText: '#6B7280',
    ZenGray2: '#ECEEF3',
    ZenLightGray: '#F2F4F7',

    ZenPositiveText: '#1E7021',
    ZenPositive: '#29CC97',
    ZenPositive_O5: '#29cc9780',
    ZenPositiveSecondary: '#DAFFF3',

    ZenOrangeText: '#F27C0E',
    ZenOrange: '#F8B133',
    ZenOrangeSecondary: '#FFFAE1',

    ZenNegative: '#FF406E',
    ZenNegativeSecondary: '#FFD5DF',

    ZenBlue: '#7BB2FD',

    customStatusBackground: '#DBECFF',
    customStatusText: '#43548E',

    additionalRed: '#FFF0F0',
    additionalGreen: '#E6FAF3',
    additionalYellow: '#FAF4E7',
  },
};
