import React from 'react';
import styled from 'styled-components';

const ProgressSteps = ({ currentStep, totalSteps, bgColor }) => {
  return (
    <ProgressStepsWrapper bgColor={bgColor} className="flex-column items-center justify-center mt-4 mb-8">
      <span className="regular-text font-24 step-text">
        {currentStep + 1} of {totalSteps}
      </span>
    </ProgressStepsWrapper>
  );
};

const ProgressStepsWrapper = styled('div')`
  padding: 8px 52px;
  background: ${({ bgColor }) => bgColor};
  width: fit-content;
  border-radius: 16px;
  margin: 16px auto 32px;
  .step-text {
    color: #ffffff;
  }
`;

export default ProgressSteps;
