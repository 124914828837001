import React from 'react';

import { Navigate, Outlet } from 'react-router-dom';

export const AuthenticationRoute = () => {
  const authed = JSON.parse(localStorage.getItem('user'));
  if (!authed) {
    return <Navigate replace to={'/login'} />;
  }
  if (authed.multifactor_token) {
    return <Outlet />;
  } else {
    return <Navigate replace to={'/home'} />;
  }
};
