import { createSlice } from '@reduxjs/toolkit';
import api from '../services';

const fileUploadSlice = createSlice({
  name: 'fileUpload',
  initialState: {},
  reducers: {},
});

export const createFileUpload = payload => async dispatch => {
  try {
    const { fileData, folderName } = payload;
    let responsesData = [];

    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('folder', folderName);
    // formData.append('backend', '');
    // formData.append('resize', '');
    // formData.append('length', fileData[i].size);
    // const fileSizeInKB = (fileData[i].size / 1024).toFixed(0) + 'KB';
    // console.log('fileSizeInKBfileSizeInKB', fileSizeInKB);
    // const numericPart = parseFloat(fileSizeInKB);
    try {
      const response = await api.post(`/files`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        timeout: 50000,
      });
      responsesData.push(response);
    } catch (error) {
      console.error('Error creating session:', error);
      throw error;
    }
    return Promise.resolve(responsesData);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const {} = fileUploadSlice.actions;
export default fileUploadSlice.reducer;
