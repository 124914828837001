import Axios from 'axios';
import isEquals from 'lodash.isequal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/images/arrow-left.svg';
import { ReactComponent as ErrorIcon } from '../../assets/images/error.svg';
import HidePassword from '../../assets/images/hide-password.svg';
import Logo from '../../assets/images/logo.svg';
import ResetIcon from '../../assets/images/reset.svg';
import ShowPassword from '../../assets/images/show-password.svg';
import TextLogo from '../../assets/images/zenterprize.svg';
import Button from '../../components/Elements/button/button';
import OtpInput from '../../components/common/otp-input/otp-input';
import PhoneInput from '../../components/common/phone-input';
import { Tooltip } from '../../components/common/tooltip/tooltip';
import { countMatchesInRegex } from '../../helpers/utils';
import { addToast } from '../../store/features/toastSlice';
import { getPasswordRequirements } from '../../store/features/userSlice';
import { ForgotPasswordWrapper } from '../../styles/pages/forgot-password.styled';

const upperCaseRegex = /[A-Z]/;
const lowerCaseRegex = /[a-z]/g;
const numberCaseRegex = /[0-9]/;
const specialCharacterRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [email, setEmail] = useState();
  const [phoneNumber, setPhoneNumber] = useState({ country: '+1', phone: '' });
  const [active, setActive] = useState();
  const [currentMFAOption, setCurrentMFAOption] = useState('SMS');
  const [currentStep, setCurrentStep] = useState('EMAIL_SMS');
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [otpValue, setOtpValue] = useState('');
  const [password, setPassword] = useState();
  const [confimPassword, setConfirmPassword] = useState();
  const [passwordError, setPasswordError] = useState();
  const [passwordRequirementError, setPasswordRequirementError] = useState({
    minumumPasswordLengthError: false,
    upperCaseError: false,
    lowerCaseError: false,
    numberError: false,
    specialCharacterError: false,
  });
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [authenticationToken, setAuthenticationToken] = useState('');
  const [passwordRequiremets, setPasswordRequirements] = useState({
    min_length: 16,
    min_digits: 1,
    min_uppercase: 1,
    min_special: 1,
    min_lowercase: 1,
  });

  const fetchPasswordRequirements = async authenticationToken => {
    dispatch(getPasswordRequirements(authenticationToken))
      .then(data => setPasswordRequirements(data))
      .catch(() => setPasswordRequirements({}));
  };

  useEffect(() => {
    if (authenticationToken) {
      fetchPasswordRequirements(authenticationToken);
    }
  }, [authenticationToken]);

  const checkPasswordRequirements = () => {
    const { min_length, min_uppercase, min_lowercase, min_digits, min_special } = passwordRequiremets;
    const minumumPasswordLengthError = password ? password?.length < min_length : true;
    const upperCaseError = min_uppercase > 0 ? countMatchesInRegex(upperCaseRegex, password) < min_uppercase : false;
    const lowerCaseError = min_lowercase > 0 ? countMatchesInRegex(lowerCaseRegex, password) < min_lowercase : false;
    const numberError = min_digits > 0 ? countMatchesInRegex(numberCaseRegex, password) < min_digits : false;
    const specialCharacterError =
      min_special > 0 ? countMatchesInRegex(specialCharacterRegex, password) < min_special : false;
    setPasswordRequirementError({
      minumumPasswordLengthError,
      upperCaseError,
      lowerCaseError,
      numberError,
      specialCharacterError,
    });
    return minumumPasswordLengthError || upperCaseError || lowerCaseError || numberError || specialCharacterError;
  };

  useEffect(() => {
    setActive(email && email.length > 2);
  }, [email]);

  useEffect(() => {
    setActive(phoneNumber.phone && phoneNumber.phone.length === 12);
  }, [phoneNumber]);

  const resetPassword = async () => {
    if (active) {
      const { phone, country } = phoneNumber;
      const phoneRequest = phone ? `${country}${phone?.replaceAll('-', '')?.replaceAll(' ', '')}` : '';
      const request =
        currentMFAOption === 'EMAIL'
          ? { destination: email, destination_type: 'EMAIL' }
          : { destination: phoneRequest, destination_type: 'SMS' };
      try {
        await Axios.post(`${process.env.REACT_APP_API_URL}/password/otp`, request);
        setCurrentStep('OTP');
      } catch (error) {
        dispatch(addToast({ error: true, text: 'Invalid authentication' }));
      }
    }
  };

  const sendOtp = async () => {
    if (otpValue && otpValue.length === 6) {
      const requestDestination =
        currentMFAOption === 'EMAIL'
          ? { destination: email, destination_type: 'EMAIL' }
          : { destination: phone, destination_type: 'SMS' };
      try {
        const { data } = await Axios.post(`${process.env.REACT_APP_API_URL}/password/otp/validate`, {
          code: otpValue,
          ...requestDestination,
        });
        setAuthenticationToken(data.access_token);
        setCurrentStep('PASSWORD');
      } catch (error) {
        setIsOtpValid(false);
      }
    }
  };

  const resendEmail = async () => {
    resetPassword();
  };

  const activate = async () => {
    if (checkPasswordRequirements()) {
      setPasswordError('Passwords do not match requirement');
      return;
    }
    if (!isEquals(password, confimPassword)) {
      setPasswordError('Passwords do not match');
      return;
    }
    try {
      await Axios.put(
        `${process.env.REACT_APP_API_URL}/users/password`,
        {
          password: password,
        },
        { headers: { authorization: `Bearer ${authenticationToken}` } },
      );
      navigate('/login');
      dispatch(addToast({ error: false, text: 'Password updated successfully.' }));
    } catch (error) {
      setPasswordError('Something happened');
    }
  };

  useEffect(() => {
    setIsOtpValid(true);
  }, [otpValue]);

  const changeMFAOption = () => {
    if (currentMFAOption === 'EMAIL') {
      setCurrentMFAOption('SMS');
      setCurrentStep('EMAIL_SMS');
      setPhoneNumber({ country: '+1', phone: '' });
    } else {
      setCurrentMFAOption('EMAIL');
      setCurrentStep('EMAIL_SMS');
      setEmail('');
    }
    setIsOtpValid(true);
    setOtpValue('');
  };

  const onBack = () => {
    if (currentStep === 'EMAIL_SMS') {
      navigate('/login');
    } else if (currentStep === 'OTP') {
      setIsOtpValid(true);
      setOtpValue('');
      setCurrentStep('EMAIL_SMS');
    } else {
      setCurrentStep('OTP');
      setPassword('');
      setConfirmPassword('');
      setPasswordError('');
      setPasswordRequirementError({
        minumumPasswordLengthError: false,
        upperCaseError: false,
        numberError: false,
        specialCharacterError: false,
      });
    }
  };

  const passwordRequirementTooltip = () => {
    const { min_length, min_uppercase, min_lowercase, min_digits, min_special } = passwordRequiremets;
    return (
      <div className="flex">
        <ErrorIcon height={16} width={16} className="error-icon" />
        <div className="flex-column ml-2">
          <span className="medium-text grey-text font-12">Password must:</span>

          {passwordRequirementError.minumumPasswordLengthError && (
            <span className="regular-text grey-text font-12">Have {min_length} characters</span>
          )}
          {passwordRequirementError.upperCaseError && (
            <span className="regular-text grey-text font-12">Have {min_uppercase} upper case</span>
          )}
          {passwordRequirementError.lowerCaseError && (
            <span className="regular-text grey-text font-12">Have {min_lowercase} upper case</span>
          )}
          {passwordRequirementError.numberError && (
            <span className="regular-text grey-text font-12">Have {min_digits} number</span>
          )}
          {passwordRequirementError.specialCharacterError && (
            <span className="regular-text grey-text font-12">Have {min_special} special character</span>
          )}
        </div>
      </div>
    );
  };

  const onEnterKey = () => {
    if (currentStep === 'EMAIL_SMS') {
      resetPassword();
    } else if (currentStep === 'OTP') {
      sendOtp();
    } else if (currentStep === 'PASSWORD') {
      activate();
    }
  };

  useEffect(() => {
    const keyEnter = event => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onEnterKey();
      }
    };

    document.addEventListener('keydown', keyEnter);

    return () => {
      document.removeEventListener('keydown', keyEnter);
    };
  }, [currentStep, currentMFAOption, email, phoneNumber, otpValue, password, confimPassword]);

  return (
    <ForgotPasswordWrapper>
      <div className="forgot-password-page flex-column items-center w-full relative">
        <div className="flex items-center justify-center cursor back-icon-container absolute" onClick={onBack}>
          <BackIcon className="back-icon" height={16} width={16} />
        </div>
        <div className="flex-column items-center fp-content card px-16 py-14">
          <img className="logo" src={Logo} />
          <img className="text-logo" src={TextLogo} alt="text-logo" />
          {currentStep === 'EMAIL_SMS' ? (
            <>
              <label className="bold-text font-20 mt-6 text-center">
                {currentMFAOption === 'EMAIL' ? t('EMAIL_TO_RECEIVE_CODE') : t('PHONE_NUMBER_TO_RECEIVE_CODE')}
              </label>
              <label className="regular-text font-12 grey-text mt-1 text-center">
                {currentMFAOption === 'EMAIL' ? t('SAME_EMAIL_ADDRESS_DETAIL') : t('SAME_PHONE_NUMBER_DETAIL')}
              </label>
              {currentMFAOption === 'EMAIL' ? (
                <div className="flex-column mt-6 w-full">
                  <label className="regular-text font-12 grey-text mb-2">{t('EMAIL_ADDRESS')}</label>
                  <div className="input-container">
                    <input
                      placeholder={t('ENTER_EMAIL_ADDRESS')}
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </div>
                </div>
              ) : (
                <div className="flex-column mt-6 w-full">
                  <label className="regular-text font-12 grey-text mb-2">{t('PHONE_NUMBER')}</label>
                  <div className="input-container relative">
                    <PhoneInput
                      setSelectedCountry={value => setPhoneNumber({ ...phoneNumber, country: value })}
                      setPhone={value => setPhoneNumber({ ...phoneNumber, phone: value })}
                      phone={phoneNumber.phone}
                      selectedCountry={phoneNumber.country}
                    />
                  </div>
                </div>
              )}
              <Button
                size="large"
                width="100%"
                className={`mt-10 primary ${!active ? 'disabled' : ''}`}
                borderRadius="16px"
                onClick={resetPassword}
                label={t('SEND')}
              />
              <Button
                size="medium"
                width="55%"
                className="mt-6 mb-2 primary-white"
                borderRadius="12px"
                onClick={changeMFAOption}
                label={currentMFAOption === 'EMAIL' ? t('SEND_CODE_BY_SMS') : t('SEND_CODE_BY_EMAIL')}
              />
            </>
          ) : currentStep === 'OTP' ? (
            <>
              <label className="bold-text font-20 mt-6">
                {currentMFAOption === 'EMAIL' ? t('WE_SENT_YOU_EMAIL') : t('WE_SENT_YOU_SMS')}
              </label>
              <label className="subtitle regular-text grey-text font-12 mt-2">
                {currentMFAOption === 'EMAIL' ? t('CHECK_EMAIL_CODE') : t('CHECK_SMS_CODE')}
              </label>
              <OtpInput
                className={'otp-input'}
                containerClass={'otp-content'}
                errorStyle={'error-style'}
                hasErrored={!isOtpValid}
                inputStyle={'input-style'}
                isInputNum={true}
                onChange={setOtpValue}
                placeholder="000000"
                value={otpValue}
              />
              {!isOtpValid && (
                <div className="flex otp-error pxy-1 mt-4">
                  <ErrorIcon height={16} width={16} />
                  <label className="medium-text white-text ml-2">{t('INCORRECT_OTP_CODE_ERROR')}</label>
                </div>
              )}
              <Button
                size="large"
                className={`mt-10 mb-6 primary ${!(otpValue && otpValue.length === 6) && 'disabled'}`}
                borderRadius="16px"
                onClick={sendOtp}
                label={t('SUBMIT')}
              />
              <div className="flex items-center cursor re-send">
                <img src={ResetIcon} alt="reset" height={16} width={16} />
                <label className="medium-text font-12 ml-2 color-purple" onClick={resendEmail}>
                  {t('RESEND_SECURE_CODE')}
                </label>
              </div>
              <Button
                size="medium"
                width="55%"
                className="mt-6 mb-2 primary-white"
                borderRadius="12px"
                onClick={changeMFAOption}
                label={currentMFAOption === 'EMAIL' ? t('SEND_CODE_BY_SMS') : t('SEND_CODE_BY_EMAIL')}
              />
            </>
          ) : currentStep === 'PASSWORD' ? (
            <>
              <label className="bold-text font-20 mt-6 mb-2"> {t('RESET_YOUR_PASSWORD')}</label>
              <label className="regular-text grey-text text-center font-12"> {t('MINIMUM_PASSWORD_REQUIREMENT')}</label>
              <div className="flex-column mt-8 w-full">
                <label className="regular-text font-12 grey-text mb-2"> {t('NEW_PASSWORD')}</label>
                <div className="password-requirement-tooltip">
                  <Tooltip
                    className="password-requirement-tooltip-details"
                    shouldOpen={false}
                    isAlwaysOpen={
                      passwordError &&
                      (passwordRequirementError.minumumPasswordLengthError ||
                        passwordRequirementError.upperCaseError ||
                        passwordRequirementError.lowerCaseError ||
                        passwordRequirementError.numberError ||
                        passwordRequirementError.specialCharacterError)
                    }
                    content={passwordRequirementTooltip()}
                    placement="right">
                    <div className="input-container relative">
                      <input
                        placeholder={t('ENTER_NEW_PASSWORD')}
                        value={password}
                        className={`${passwordError && 'error-input'}`}
                        onChange={e => {
                          setPasswordError(false);
                          setPassword(e.target.value);
                        }}
                        type={showNewPassword ? 'text' : 'password'}
                      />
                      <img
                        className="show-password"
                        src={showNewPassword ? HidePassword : ShowPassword}
                        alt="show password"
                        onClick={() => setShowNewPassword(!showNewPassword)}
                      />
                    </div>
                  </Tooltip>
                </div>
              </div>
              <div className="flex-column mt-6 w-full">
                <label className="regular-text font-12 grey-text mb-2"> {t('CONFIRM_NEW_PASSWORD')}</label>
                <div className="input-container relative">
                  <input
                    placeholder={t('CONFIRM_NEW_PASSWORD')}
                    value={confimPassword}
                    className={`${passwordError && 'error-input'}`}
                    onChange={e => {
                      setPasswordError(false);
                      setConfirmPassword(e.target.value);
                    }}
                    type={showConfirmPassword ? 'text' : 'password'}
                  />
                  <img
                    className="show-password"
                    src={showConfirmPassword ? HidePassword : ShowPassword}
                    alt="show password"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  />
                </div>
              </div>
              {passwordError && (
                <div className="flex items-center password-error pxy-1 w-full mt-6">
                  <ErrorIcon height={16} width={16} />
                  <label className="medium-text white-text ml-2">{passwordError}</label>
                </div>
              )}
              <Button
                label={t('SET_NEW_PASSWORD')}
                className="mt-10 mb-6 primary"
                size="large"
                borderRadius="16px"
                onClick={activate}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </ForgotPasswordWrapper>
  );
};

export default ForgotPassword;
