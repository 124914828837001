import styled from 'styled-components';

const CommonPopupWrapper = styled.div`
  width: 456px;
  padding: 32px 48px 48px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default CommonPopupWrapper;
