import styled from 'styled-components';

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-family: Roboto Bold;
  font-style: normal;
  width: ${props => props.width};
  cursor: pointer;
  padding: 8px;
  color: ${props => props.color};
  background: ${props => props.bgColor};
  height: ${props => props.height};
  font-size: ${props => props.fontSize};
  border: ${props => props.borderWidth} solid ${props => props.borderColor};
  border-radius: ${props => props.borderRadius};

  * {
    cursor: pointer;
  }

  svg {
    margin-right: 8px;
  }
  label {
    color: ${props => props.color};
  }

  &.primary {
    background: ${({ theme }) => theme.colors.ZenPurple};
    border: 1px solid ${({ theme }) => theme.colors.ZenPurple};
    width: 100%;

    label {
      color: #ffffff;
    }
    &:hover {
      background: ${({ theme }) => theme.colors.ZenPurpleSecondary3};
      border: 1px solid ${({ theme }) => theme.colors.ZenPurpleSecondary3};
    }
  }

  &.secondary {
    background: ${({ theme }) => theme.colors.ZenDefaultSecondary};
    border: 1px solid ${({ theme }) => theme.colors.ZenDefaultSecondary};
    width: 100%;

    label {
      color: #ffffff;
    }
  }

  &.negative {
    background: ${({ theme }) => theme.colors.ZenNegative};
    border: 1px solid ${({ theme }) => theme.colors.ZenNegative};
    width: 100%;

    label {
      color: #ffffff;
    }
  }

  &.primary-white {
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.colors.ZenPurple};

    label {
      color: ${({ theme }) => theme.colors.ZenPurple};
    }
  }

  &.default-secondary {
    background: ${({ theme }) => theme.colors.ZenPurpleSecondary2};
    border: 1px solid ${({ theme }) => theme.colors.ZenPurpleSecondary2};

    label {
      color: ${({ theme }) => theme.colors.ZenPurple};
    }
  }

  &.disabled {
    opacity: 0.3;
    label {
      color: #ffffff;
    }

    svg {
      * {
        stroke: #ffffff;
      }
    }
  }

  &.primary-grey {
    background: #ffffff;
    border: 1px solid ${({ theme }) => theme.colors.ZenGray2};

    label {
      color: ${({ theme }) => theme.colors.Regular_Text};
    }
  }
`;

export default ButtonWrapper;
