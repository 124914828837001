import React from 'react';

const Success = () => {
  return (
    <div className="flex flex-1 items-center justify-center">
      <span className="regular-text font-24">Payment succeeded!</span>
    </div>
  );
};

export default Success;
