import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { removeToast } from '../../store/features/toastSlice';

import CloseIcon from '../../assets/images/toast-close.svg';
import ToastWrapper from './toast.styled';

const Toast = () => {
  const dispatch = useDispatch();
  const { toasts } = useSelector(state => state.toast);

  const onRemoveToast = toast => {
    dispatch(removeToast(toast));
  };

  return (
    <ToastWrapper className="ignore-click">
      <TransitionGroup>
        {toasts &&
          toasts
            .reduce((acc, curr) => {
              if (!acc.find(toast => toast.id === curr.id)) {
                acc.push(curr);
              }
              return acc;
            }, [])
            .map((toast, i) => (
              <CSSTransition key={i} classNames="fade" timeout={200}>
                <div key={i} className="toast">
                  <div className="toast-left">
                    <div className="toast-color" style={{ background: toast.error ? '#FF5B5B' : '#32E5C5' }} />
                    <div className="toast-cotent">
                      <label className="medium-text">
                        {toast.title ? toast.title : toast.error ? 'Error' : 'Success'}
                      </label>
                      <label className="regular-text">{toast.text}</label>
                    </div>
                  </div>
                  <img alt="iocn" className="close-btn" onClick={() => onRemoveToast(toast)} src={CloseIcon} />
                </div>
              </CSSTransition>
            ))}
      </TransitionGroup>
    </ToastWrapper>
  );
};

export default Toast;
