import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  getAddressDataForAuth,
  getAddressDataForLaunch,
  getAddressesForAuth,
  getAddressesForLaunch,
} from '../../../store/features/formSlice';
import Dropdown from '../dropdown/index';

const apiForLaunch = {
  getAddresses: getAddressesForLaunch,
  getAddressData: getAddressDataForLaunch,
};

const apiForAuth = {
  getAddresses: getAddressesForAuth,
  getAddressData: getAddressDataForAuth,
};

const Address = ({
  name,
  is_required,
  placeholder,
  font = null,
  fontFamily,
  showRequiredError,
  selectedValue,
  onAddressUpdate = () => {},
  sub_label = null,
  fromLaunch = false,
  api_key = null,
}) => {
  const dispatch = useDispatch();

  const apiToUse = fromLaunch ? apiForLaunch : apiForAuth;

  const [inputValue, setInputValue] = useState(null);

  const loadOptions = inputValue => {
    return dispatch(apiToUse.getAddresses({ param: { search: inputValue }, api_key }))
      .then(data => {
        return data.map(d => ({ ...d, label: d.address, value: d.id }));
      })
      .catch(e => {
        return [];
      });
  };

  const onInputChange = (text, { action }) => {
    if (action === 'input-change') {
      setInputValue(text);
      onAddressUpdate({ formatted_address: text, line1: text }, true);
    }
  };

  const onAddressSelect = address => {
    dispatch(apiToUse.getAddressData({ id: address.id, api_key }))
      .then(data => {
        onAddressUpdate(data, false);
      })
      .catch(e => {
        return '';
      });
  };

  return (
    <AddressWrapper className="flex items-start">
      <Dropdown
        name={name}
        is_required={is_required}
        placeholder={placeholder}
        font={font}
        fontFamily={fontFamily}
        isSearchable={true}
        onFocus={() => setInputValue(selectedValue || '')}
        showRequiredError={showRequiredError}
        loadOptions={loadOptions}
        onInputChange={onInputChange}
        inputValue={inputValue}
        onChange={onAddressSelect}
        selectedValue={selectedValue ? { label: selectedValue } : null}
        sub_label={sub_label}
      />
    </AddressWrapper>
  );
};

const AddressWrapper = styled('div')``;

export default Address;
