import React from 'react';
import styled from 'styled-components';
import Loading from '../../../assets/images/loading.svg';

const Loader = ({ height = 60 }) => {
  return (
    <LoaderWrapper className="w-full h-full flex items-center justify-center">
      <img alt="loading" height={height} src={Loading} />
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled('div')``;

export default Loader;
