import React, { useEffect } from 'react';
import StripeWrapper from './stripe-element';

const Payment = ({
  selectedProduct,
  backgroundColor = '',
  setPaymentDetails = () => {},
  onSubmitForm = () => {},
  selectedAddress = {},
  selectedBillingAddress = {},
  integration,
  api_key = '',
  font = null,
  fontFamily,
  showBillingAddress,
}) => {
  const { option: subscription, component } = selectedProduct || {};

  useEffect(() => {
    setPaymentDetails({ isConfirmed: false, isCollectPayment: true });
  }, []);

  return (
    <div className="flex-column mt-6">
      <StripeWrapper
        backgroundColor={backgroundColor}
        subscription={subscription}
        setPaymentDetails={setPaymentDetails}
        onSubmitForm={onSubmitForm}
        selectedAddress={selectedAddress}
        selectedBillingAddress={selectedBillingAddress}
        productComponentName={component?.name || 'product'}
        integration={integration}
        api_key={api_key}
        font={font}
        showBillingAddress={showBillingAddress}
        fontFamily={fontFamily}
      />
    </div>
  );
};

export default Payment;
