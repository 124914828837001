import React, { useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-canvas';
import styled from 'styled-components';
import Button from '../button/button';

const Signature = ({
  name,
  is_required,
  onChange = () => {},
  font = null,
  fontFamily,
  showRequiredError,
  signatureValue = null,
  sub_label = null,
}) => {
  const signRef = useRef();
  const containerRef = useRef();

  const [canvasWidth, setCanvasWidth] = useState(300);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);

  useEffect(() => {
    if (containerRef?.current) {
      setCanvasWidth(containerRef?.current.getBoundingClientRect().width);
    }
  }, [containerRef?.current]);

  useEffect(() => {
    if (signRef?.current && signatureValue && containerRef?.current) {
      const canvasWidth = containerRef?.current.getBoundingClientRect().width;
      signRef.current?.clear();
      signRef?.current.fromDataURL(signatureValue, { width: canvasWidth, height: 140 });
    }
  }, [signRef?.current]);

  return (
    <div className="flex-column justify-center" ref={containerRef}>
      <div className="mb-1">
        <span
          className="regular-text"
          style={{ fontSize: `${font?.size || 14}px`, color: font?.color || '#6B7280', ...fontFamily }}>
          {name}
        </span>
        {is_required && <span className="required-star ml-1">*</span>}
      </div>
      <SignatureWrapper className="flex-column items-center w-full">
        <SignaturePad
          ref={signRef}
          onEnd={() => {
            onChange(signRef.current?.toDataURL());
          }}
          canvasProps={{
            className: `signCanvas ${showRequiredError && isCanvasEmpty && 'required-error'}`,
            width: canvasWidth,
            height: 140,
          }}
          penColor="#16192C"
        />
        <Button
          onClick={() => {
            signRef.current?.clear();
            setIsCanvasEmpty(signRef.current?.isEmpty());
            onChange(null);
          }}
          label="Clear"
          size="small"
          className="primary-white mt-2"
          width="100px"
        />
      </SignatureWrapper>
      {sub_label && (
        <div className="mt-1">
          <span className="regular-text gray-500-text">{sub_label}</span>
        </div>
      )}
    </div>
  );
};

const SignatureWrapper = styled('div')`
  .signCanvas {
    border: 1px solid ${({ theme }) => theme.colors.ZenGraySecondary};
    border-radius: 18px;
  }

  .required-error {
    border-color: ${({ theme }) => theme.colors.ZenNegative};
  }
`;

export default Signature;
