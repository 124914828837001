import styled from 'styled-components';

export const FormDetailWrapper = styled.div`
  padding: 0 32px;
  max-width: 100%;
  height: ${({ fromLaunch }) => (fromLaunch ? '100dvh' : '100%')};
  padding: ${({ fromLaunch, sessionCode }) => (fromLaunch ? 0 : sessionCode ? 130 : 100)}px 0 0;
  overflow: scroll;

  .form-wrapper {
    width: 613px;
    height: auto;
    border-radius: 24px;
    margin: ${({ fromLaunch }) => (fromLaunch ? 40 : 0)}px auto 0;
    max-width: 100%;

    @media only screen and (max-width: 768px) {
      .device-scale-component,
      .form-action-wrapper {
        flex-direction: column;

        .confirm-component {
          padding: 0;
        }
      }

      .form-action-wrapper {
        row-gap: 16px;
      }
    }
  }

  .form-content {
    margin: 8px 24px 40px 24px;
  }

  .form-action-wrapper {
    padding: 40px;
    border-top: 1px solid;
  }

  .content-container {
    padding-top: 80px;
  }
`;
