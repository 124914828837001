import React from 'react';
import styled from 'styled-components';

const HeaderElement = ({ name, font, fontFamily, alignment = {} }) => {
  return (
    <HeaderWrapper
      className="flex items-center justify-center mt-2 regular-text w-full"
      style={{
        fontSize: `${font.size || 14}px`,
        color: `${font.color || '#16192C'}`,
        fontFamily: `${font.name || 'Poppins'}`,
        ...fontFamily,
        ...alignment,
      }}>
      {name}
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled('label')`
  white-space: pre-wrap;
`;

export default HeaderElement;
