export const TRANSLATIONS_EN_GB = {
  EMAIL_ADDRESS: 'Email address',
  PASSWORD: 'Password',
  ENTER_PASSWORD: 'Enter password',
  LOGIN: 'Login',
  FORGOT_PASSWORD: 'Forgot my password',
  INCORRECT_EMAIL_PASSWORD: 'Incorrect email or password',
  EMAIL_TO_RECEIVE_CODE: 'Enter your email to receive the security code',
  ENTER_EMAIL_ADDRESS: 'Enter Email address',
  WE_SENT_YOU_EMAIL: 'We sent you an email',
  SEND: 'Send',
  SECURITY_REASON_DETAIL_TEXT:
    'For added security, we need to further verify your account. Please, enter security code from email here',
  INCORRECT_OTP_CODE_ERROR: 'Incorrect code - please check your code is correct and try again',
  VERIFY: 'Verify',
  SUBMIT: 'Submit',
  RESEND_SECURE_CODE: 'Resend secure code',
  RESET_YOUR_PASSWORD: 'Reset your password',
  NEW_PASSWORD: 'New password',
  ENTER_NEW_PASSWORD: 'Enter new password',
  CONFIRM_NEW_PASSWORD: 'Confirm new password',
  SET_NEW_PASSWORD: 'Set new password',
  SEND_CODE_BY_SMS: 'Send code by SMS',
  SEND_CODE_BY_EMAIL: 'Send code by email',
  SAME_EMAIL_ADDRESS_DETAIL: 'Please ensure you enter the same email address registered with your account',
  PHONE_NUMBER_TO_RECEIVE_CODE: 'Enter your phone number to receive the security code',
  SAME_PHONE_NUMBER_DETAIL: 'Please ensure you enter the same phone number registered with your account',
  PHONE_NUMBER: 'Phone number',
  WE_SENT_YOU_SMS: 'We sent you an SMS',
  CHECK_SMS_CODE: 'Check your SMS and enter your security code below',
  CHECK_EMAIL_CODE: 'Check your email and enter your security code below',
  MINIMUM_PASSWORD_REQUIREMENT:
    'Minimum password 16 characters, at least one upper case, one number, and one special character',
  EMAIL: 'Email',
  PHONE: 'Phone',
  MY_PROFILE: 'My profile',
  REFRESH: 'Refresh',
  NEW_VERSION_AVAILABLE: 'New version available!',
  NEW_VERSION_DESCRIPTION: ' Please refresh the page to get access to new form updates',
  WE_DO_NOT_ACCEPT_PREPAID_CARDS: ' We do not accept prepaid cards.',
  OFFLINE_OR_POOR_WIFI:
    'Seems like you are offline or on a poor wifi connection, please connect to a stronger wifi connection and try again',
};
