import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import FlagUK from '../../../assets/images/flag-uk.svg';
import FlagUS from '../../../assets/images/flag-us.svg';
import DropDown from '../common-dropdown';

const PhoneInput = ({ selectedCountry = '+1', setSelectedCountry, phone, setPhone, className = '' }) => {
  const countryList = [
    { name: '+44', value: '+44', icon: FlagUK },
    { name: '+1', value: '+1', icon: FlagUS },
  ];

  const maskField = {
    '+1': [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    '+44': [/\d/, /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/],
  };

  return (
    <PhoneInputWrapper className={`flex relative ${className}`}>
      <DropDown
        options={countryList}
        icon={true}
        getIcon={option => option.icon}
        className="absolute h-full phone-input-dropdown"
        placeholder={'+1'}
        selected={countryList.find(c => c.value === selectedCountry)}
        setSelected={country => setSelectedCountry(country.value)}
      />
      <MaskedInput
        mask={maskField[selectedCountry]}
        value={phone || null}
        className="input w-full phone-input"
        placeholder={selectedCountry === '+1' ? '000-000-0000' : '0000 000 000'}
        guide={false}
        onChange={({ target: { value } }) => setPhone(value)}
      />
    </PhoneInputWrapper>
  );
};

const PhoneInputWrapper = styled('div')`
  .phone-input-dropdown {
    .organisations-border {
      border: none;
      height: 100%;
      width: 110px;
    }

    .option-name,
    .selected-name {
      font-size: 16px;
    }

    .mc-option-item:hover label {
      color: ${({ theme }) => theme.colors.RegularText};
    }
  }

  .phone-input {
    padding-left: 110px !important;
  }

  .error-border {
    border-color: ${({ theme }) => theme.colors.zenNegative} !important;
  }
`;

export default PhoneInput;
