import Success from '../components/Elements/payment/success';
import FormDetail from '../pages/form-detail';
import Forms from '../pages/forms';
import Profile from '../pages/profile';

export const ROUTE_PATHS = [
  {
    id: 0,
    path: '/forms',
    Component: Forms,
  },
  {
    id: 1,
    path: '/forms/:id',
    Component: FormDetail,
  },
  {
    id: 2,
    path: '/success',
    Component: Success,
  },
  { id: 3, path: '/profile', Component: Profile },
];
