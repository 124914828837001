import { createSlice } from '@reduxjs/toolkit';
import api from '../services';
import apiLaunch from '../services/api_launch';

export const formSlice = createSlice({
  name: 'forms',
  initialState: {
    form: {},
    formPanel: [],
    formWidget: [],
    selectedFormPanel: {},
    formSessionData: [],
    formSession: {},
  },
  reducers: {
    setFormsList: (state, { payload }) => {
      if (payload.merge) {
        const mergeData = { ...payload?.data, content: [...state?.formsList?.content, ...payload?.data?.content] };
        state.formsList = mergeData;
      } else {
        state.formsList = payload.data;
      }
      state.form = {};
      state.formPanel = [];
      state.formWidget = [];
      state.selectedFormPanel = {};
      state.formSessionData = [];
      state.formSession = {};
    },
    setForm: (state, { payload }) => {
      state.form = payload;
    },
    setFormPanel: (state, { payload }) => {
      state.formPanel = payload;
    },
    setFormWidget: (state, { payload }) => {
      state.formWidget = payload;
    },
    setWidgetForPanel: (state, { payload }) => {
      state.formPanel = state.formPanel?.map(fp => (fp.id === payload.id ? { ...fp, widgets: payload.data } : fp));
    },
    setSelectedFormPanel: (state, { payload }) => {
      state.selectedFormPanel = payload;
    },
    setFormSession: (state, { payload }) => {
      state.formSession = payload;
    },
    setFormSessionData: (state, { payload }) => {
      state.formSessionData = payload;
    },
    setFormSessionDataWithMerge: (state, { payload }) => {
      state.formSessionData = [
        ...(state.formSessionData || [])?.filter(s => !payload?.componentIds?.includes(s.panel_widget_component?.id)),
        ...payload.data,
      ];
    },
  },
});

const updatedWidgetData = widgetData => {
  return widgetData?.map(w => ({
    ...w,
    components: w?.components?.map(c => ({
      ...c,
      component_layout: c.lexicon === 'contact.address.state' ? 'STATE' : c.component_layout,
    })),
  }));
};

export const getFormsList = payload => async dispatch => {
  try {
    const { params, merge } = payload;
    const { data } = await api.get(`/prizedraws/forms?status=ACTIVE`, { params: params });
    dispatch(setFormsList({ data, merge }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormForAuth = payload => async dispatch => {
  try {
    const { data } = await api.get(`/self-service/forms/${payload.id}`);
    dispatch(setForm(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormPanelForAuth = payload => async dispatch => {
  try {
    const { data } = await api.get(`/self-service/forms/${payload.id}/panels`);
    dispatch(setFormPanel(data));
    dispatch(setSelectedFormPanel(data?.[0] || {}));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormWidgetForAuth = payload => async dispatch => {
  try {
    const { data } = await api.get(`/self-service/panels/${payload.id}/widgets`);
    const widgetData = updatedWidgetData(data);
    dispatch(setFormWidget(widgetData));
    dispatch(setWidgetForPanel({ id: payload.id, data: widgetData }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAddressesForAuth = payload => async () => {
  try {
    const { data } = await api.get(`/addresses`, { params: payload.param });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAddressDataForAuth = payload => async () => {
  try {
    const { id } = payload;
    const { data } = await api.get(`/addresses/${id}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createFormSessionForAuth = payload => async dispatch => {
  try {
    const { request } = payload;
    const { data } = await api.post(`/form_sessions`, request);
    dispatch(setFormSession(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateFormSessionForAuth = payload => async dispatch => {
  try {
    const { id, request } = payload;
    const { data } = await api.put(`/form_sessions/${id}`, request);
    dispatch(setFormSession(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const submitFormSessionDataForAuth = payload => async dispatch => {
  try {
    const { id, request, Idempotency_Key } = payload;
    const { data } = await api.put(`/form_sessions/${id}/submission_data`, request, {
      headers: { 'Idempotency-Key': Idempotency_Key },
    });
    dispatch(setFormSessionDataWithMerge({ data, componentIds: data?.map(d => d.panel_widget_component?.id) }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormForLaunch = payload => async dispatch => {
  try {
    const { id } = payload;
    const { data } = await apiLaunch.get(`/self-service/forms/${id}`);
    dispatch(setForm(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormPanelForLaunch = payload => async dispatch => {
  try {
    const { api_key, id } = payload;
    const { data } = await apiLaunch.get(`/self-service/forms/${id}/panels`, { headers: { api_key } });
    dispatch(setFormPanel(data));
    dispatch(setSelectedFormPanel(data?.[0] || {}));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFormWidgetForLaunch = payload => async dispatch => {
  try {
    const { api_key, id } = payload;
    const { data } = await apiLaunch.get(`/self-service/panels/${id}/widgets`, { headers: { api_key } });
    const widgetData = updatedWidgetData(data);
    dispatch(setFormWidget(widgetData));
    dispatch(setWidgetForPanel({ id: id, data: widgetData }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAddressesForLaunch = payload => async () => {
  try {
    const { api_key, param } = payload;
    const { data } = await apiLaunch.get(`/addresses`, { params: param, headers: { api_key } });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAddressDataForLaunch = payload => async () => {
  try {
    const { id, api_key } = payload;
    const { data } = await apiLaunch.get(`/addresses/${id}`, { headers: { api_key } });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createFormSessionForLaunch = payload => async dispatch => {
  try {
    const { request, api_key } = payload;
    const { data } = await apiLaunch.post(`/form_sessions`, request, { headers: { api_key } });
    dispatch(setFormSession(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const updateFormSessionForLaunch = payload => async dispatch => {
  try {
    const { id, request, api_key } = payload;
    const { data } = await apiLaunch.put(`/form_sessions/${id}`, request, { headers: { api_key } });
    dispatch(setFormSession(data));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const submitFormSessionDataForLaunch = payload => async dispatch => {
  try {
    const { id, request, api_key, Idempotency_Key } = payload;
    const { data } = await apiLaunch.put(`/form_sessions/${id}/submission_data`, request, {
      headers: { api_key, 'Idempotency-Key': Idempotency_Key },
    });
    dispatch(setFormSessionDataWithMerge({ data, componentIds: data?.map(d => d.panel_widget_component?.id) }));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createContact = payload => async dispatch => {
  try {
    const { request, api_key, Idempotency_Key } = payload;
    const { data } = await apiLaunch.post(`/contacts`, request, {
      headers: { api_key, 'Idempotency-Key': Idempotency_Key },
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createPaymentMethod = payload => async dispatch => {
  try {
    const { contact_id, request, api_key, Idempotency_Key } = payload;
    const { data } = await apiLaunch.post(`/contacts/${contact_id}/payment_methods`, request, {
      headers: { api_key, 'Idempotency-Key': Idempotency_Key },
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const createSubscription = payload => async dispatch => {
  try {
    const { contact_id, request, api_key, Idempotency_Key } = payload;
    const { data } = await apiLaunch.post(`/contacts/${contact_id}/subscriptions`, request, {
      headers: { api_key, 'Idempotency-Key': Idempotency_Key },
    });
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
export const {
  setFormsList,
  setForm,
  setFormPanel,
  setFormWidget,
  setSelectedFormPanel,
  setWidgetForPanel,
  setFormSession,
  setFormSessionData,
  setFormSessionDataWithMerge,
} = formSlice.actions;
export default formSlice.reducer;
