import React from 'react';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components';
import { ReactComponent as FlagUS } from '../../../assets/images/flag-us.svg';

const PhoneInputUs = ({ phone, setPhone, className = '' }) => {
  return (
    <PhoneInputUsWrapper className={`flex relative ${className}`}>
      <div className="absolute flex items-center px-4 h-full country-label">
        <label className={`regular-text selected-name flex-1 flex`}>
          <span className="flex items-center mr-2">
            <FlagUS />
          </span>
          +1
        </label>
      </div>
      <MaskedInput
        mask={[/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
        value={phone || null}
        className="input w-full phone-input"
        placeholder={'000-000-0000'}
        guide={false}
        onChange={({ target: { value } }) => setPhone(value)}
      />
    </PhoneInputUsWrapper>
  );
};

const PhoneInputUsWrapper = styled('div')`
  .phone-input {
    padding-left: 70px !important;
  }

  .error-border {
    border-color: ${({ theme }) => theme.colors.ZenNegative} !important;
  }
`;

export default PhoneInputUs;
