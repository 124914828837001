import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ArrowLeft from '../../../assets/images/arrow-left.svg';
import ArrowRight from '../../../assets/images/arrow-right.svg';
import DropDown from '../../common/common-dropdown';

const DateSelector = ({
  startDate,
  setStartDate = () => {},
  endDate,
  setEndDate = () => {},
  range,
  showCurrentMonth = true,
  isOnlyWeekDays = false,
  weekday = 0,
  disablePastDays = false,
  top,
}) => {
  const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

  const [value, setValue] = useState(showCurrentMonth ? moment() : startDate);
  const [calendar, setCalendar] = useState([]);

  const startDay = value.clone().startOf('month').day(1);
  const endDay = value.clone().endOf('month').day(7);

  const currentDateRef = useRef(moment());

  const yearRange = (start, end) => {
    return Array(start - end + 1)
      .fill()
      .map((_, idx) => start - idx)
      .map(y => ({ name: y, value: y }));
  };

  const years = yearRange(moment().get('year') + 5, 1900);

  useEffect(() => {
    const day = startDay.clone().subtract(1, 'day');
    const a = [];
    while (day.isBefore(endDay, 'day')) {
      a.push(
        Array(7)
          .fill(0)
          .map(() => day.add(1, 'day').clone()),
      );
    }
    setCalendar(a);
  }, [value]);

  const changeSelectedDate = day => {
    if (range) {
      setStartDate(day.clone().weekday(weekday).unix());
      const addedDays = isOnlyWeekDays ? 5 : weekday === 0 ? 6 : 7;
      setEndDate(day.clone().weekday(addedDays).unix());
    } else {
      setStartDate(day.clone().startOf('day').unix());
      setEndDate(day.clone().endOf('day').unix());
    }
  };

  const onYearChange = year => {
    setValue(value.set('year', year.value).clone());
  };

  const getSelectedYear = value => {
    const year = value.get('year') || moment().get('year');
    return years.find(y => y.value === year);
  };

  return (
    <DropdownWrapper className="py-5 px-6 absolute date-selector" style={{ top: top ? top : '' }}>
      <div className="month-selector mx-3">
        <img
          className="py-2"
          onClick={e => {
            e.stopPropagation();
            setValue(value.subtract(1, 'month').clone());
          }}
          src={ArrowLeft}
        />
        <div className="flex items-center">
          <label className="month normal-text">{value.format('MMMM')}</label>
          <DropDown
            options={years}
            selected={getSelectedYear(value)}
            setSelected={o => onYearChange(o)}
            className="ml-4 year-select-dropdown"
          />
        </div>
        <img
          className="py-2"
          onClick={e => {
            e.stopPropagation();
            setValue(value.add(1, 'month').clone());
          }}
          src={ArrowRight}
        />
      </div>
      <div className="week-days">
        {weekDays.map(wd => (
          <div key={wd} className="week-day">
            <label className="normal-text font-14">{wd}</label>
          </div>
        ))}
      </div>
      {calendar.map((week, j) => (
        <div key={j} className="week">
          {week.map((day, i) => (
            <div
              key={i}
              className={`day regular-text cursor ${!value.isSame(day, 'month') && 'fade'}
               ${
                 range &&
                 ((day.isAfter(startDate) && day.isBefore(endDate) && !day.isSame(endDate, 'day')) ||
                   day.isSame(startDate, 'day') ||
                   day.isSame(endDate, 'day')) &&
                 'range-day'
               } ${disablePastDays && !currentDateRef.current.isBefore(day, 'date') && 'fade'}`}
              onClick={() => {
                if (!disablePastDays || (disablePastDays && currentDateRef.current.isBefore(day, 'date'))) {
                  changeSelectedDate(day);
                }
              }}>
              <div
                className={`w-full h-full flex items-center justify-center ${
                  day.isSame(startDate, 'day') && 'selected-day'
                } ${range && day.isSame(endDate, 'day') && 'selected-day'} `}>
                <label>{day.format('D')}</label>
              </div>
            </div>
          ))}
        </div>
      ))}
    </DropdownWrapper>
  );
};

const DropdownWrapper = styled('div')`
  width: 328px;
  height: 396px;
  background: #ffffff;
  box-shadow: 2px 8px 30px rgba(5, 49, 73, 0.1);
  border-radius: 16px;
  user-select: none;
  z-index: 3;
  top: 10px;
  right: 0px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  user-select: none;

  .week-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    user-select: none;

    .week-day {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
    }
  }

  .week {
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    .day {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      position: relative;
      outline: 0;
    }

    .day:hover {
      color: #8927ef;
      background: #f5f9fc;
      border-radius: 20px;
    }

    .weekend {
      color: #053149;
      opacity: 0.5;
    }

    .fade {
      color: #a0a0a0;
      opacity: 0.3;
    }

    .selected-day {
      color: white;
      background: #8927ef;
      border-radius: 20px;
    }

    .range-day {
      background: #faf4e7;
    }

    .campaign-day {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 1px solid #ea5454;
      cursor: pointer;
    }
  }

  .month-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    user-select: none;

    img {
      cursor: pointer;
      min-width: 10px;
      min-height: 10px;
    }
  }

  .year-select-dropdown {
    height: 24px;
    .organisations-border {
      height: 24px;
      padding: 4px;
      border-radius: 6px;
      background-color: ${({ theme }) => theme.colors.ZenLightGray};
      border-color: ${({ theme }) => theme.colors.ZenLightGray};
    }
    .input-arrow {
      display: none;
    }
    .mc-options {
      top: 30px;
      background: #ffffff;
      box-shadow: 2px 8px 30px rgba(5, 49, 73, 0.1);
      border-radius: 16px;
      max-height: 275px;
      width: 85px;
    }
  }
`;

export default DateSelector;
