import { Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getDataFromSessionData, getUserBillingAddress, getUserData } from '../../../helpers/utils';
import { createContact, createPaymentMethod, createSubscription } from '../../../store/features/formSlice';
import { addToast } from '../../../store/features/toastSlice';
import Button from '../button/button';

const StripePayment = ({
  backgroundColor,
  subscription,
  setPaymentDetails,
  onSubmitForm,
  selectedAddress,
  selectedBillingAddress,
  productComponentName,
  integration,
  api_key,
  showBillingAddress,
}) => {
  const { formSessionData, selectedFormPanel } = useSelector(state => state.forms);
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [message, setMessage] = useState(null);
  const [paymentConfirmationMessage, setPaymentConfirmationMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const generateNewIdempotencyKey = (defaultKeysToIgnore = {}) => {
    return {
      contacts: {
        key: uuidv4(),
      },
      paymentMethods: {
        key: uuidv4(),
      },
      subscriptions: {
        key: uuidv4(),
      },
      ...defaultKeysToIgnore,
    };
  };

  const Idempotency_Key = useRef(generateNewIdempotencyKey());

  const getUserStripeBillingDetails = () => {
    return {
      name: `${getDataFromSessionData(formSessionData, selectedAddress, 'contact.forename')} ${getDataFromSessionData(
        formSessionData,
        selectedAddress,
        'contact.surname',
      )}`,
      email: getDataFromSessionData(formSessionData, selectedAddress, 'contact.email'),
      phone: getDataFromSessionData(formSessionData, selectedAddress, 'contact.mobile'),
      address: {
        country:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'country_code') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.country_code'),
        city:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'city') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.city') ||
          '',
        state:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'state') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.state') ||
          '',
        line1:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'line1') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.line1') ||
          '',
        line2:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'line2') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.line2') ||
          '',
        postal_code:
          getUserBillingAddress(selectedBillingAddress, showBillingAddress, 'postcode') ||
          getDataFromSessionData(formSessionData, selectedAddress, 'contact.address.postcode') ||
          '',
      },
    };
  };

  const usersData = useMemo(() => {
    return getUserData(formSessionData, selectedAddress, selectedBillingAddress, showBillingAddress);
  }, [formSessionData, selectedAddress, selectedBillingAddress, showBillingAddress]);

  const userStripeBillingDetails = useMemo(() => {
    return getUserStripeBillingDetails();
  }, [selectedBillingAddress, showBillingAddress]);

  const currentSubscription = useMemo(() => {
    return subscription;
  }, [subscription]);

  useEffect(() => {
    Idempotency_Key.current = generateNewIdempotencyKey();
  }, [usersData, userStripeBillingDetails]);

  useEffect(() => {
    Idempotency_Key.current = generateNewIdempotencyKey({ contacts: { ...Idempotency_Key.current.contacts } });
  }, [currentSubscription]);

  const onSubmit = async () => {
    if (!api_key) {
      dispatch(addToast({ error: true, text: 'There is no api key available.' }));
      return;
    }
    if (!subscription) {
      dispatch(addToast({ error: true, text: `Please select ${productComponentName}` }));
      return;
    }
    if (!stripe || !elements) {
      return;
    }
    setIsLoading(true);
    const { error: submitError } = await elements.submit();

    if (submitError) {
      setIsLoading(false);
      return;
    }

    setMessage(null);
    const { contacts, paymentMethods, subscriptions } = Idempotency_Key.current;

    let contactData = null;
    let paymentMethodData = null;
    let subs = null;

    dispatch(
      createContact({
        request: {
          ...getUserData(formSessionData, selectedAddress, selectedBillingAddress, showBillingAddress),
          integration: { id: integration.id },
        },
        api_key: api_key,
        Idempotency_Key: contacts.key,
      }),
    )
      .then(async data => {
        contactData = data;
        const { error: paymentMethodError, paymentMethod } = await stripe.createPaymentMethod({
          elements,
          params: {
            billing_details: getUserStripeBillingDetails(),
          },
        });

        if (paymentMethodError) {
          setIsLoading(false);
          setMessage(paymentMethodError.message);
          return;
        }

        dispatch(
          createPaymentMethod({
            request: {
              external_reference: paymentMethod.id,
              integration: { id: integration.id },
              is_default: true,
            },
            api_key: api_key,
            contact_id: contactData.id,
            Idempotency_Key: paymentMethods.key,
          }),
        )
          .then(async postPaymentMethodData => {
            paymentMethodData = postPaymentMethodData;
            dispatch(
              createSubscription({
                request: {
                  id: subscription?.value,
                  payment_method: { id: postPaymentMethodData.external_reference },
                  product: { id: subscription.product_id },
                  price: { id: subscription.price_id },
                  integration: { id: integration.id },
                  vendor: null,
                },
                api_key: api_key,
                contact_id: contactData.id,
                Idempotency_Key: subscriptions.key,
              }),
            )
              .then(async subsData => {
                subs = subsData;
                const { error, paymentIntent } = await stripe.confirmPayment({
                  elements,
                  clientSecret: subs.client_secret,
                  confirmParams: {
                    return_url: `${window.location.protocol + '//' + window.location.host}/#/forms`,
                  },
                  redirect: 'if_required',
                });

                if (paymentIntent || error?.payment_intent?.status === 'succeeded') {
                  setPaymentConfirmationMessage('Your payment is completed');
                  if (selectedFormPanel?.submit_onpayment) {
                    await onSubmitForm(contactData.id, subs?.external_reference, subs?.id, false, setMessage);
                  } else {
                    setPaymentDetails({
                      isCollectPayment: true,
                      isConfirmed: true,
                      contactId: contactData.id,
                      external_reference: subs?.external_reference,
                      subscription_id: subs?.id,
                    });
                  }
                  setIsLoading(false);
                } else if (error) {
                  if (error.type === 'card_error' || error.type === 'validation_error') {
                    const errorMessage =
                      error?.decline_code === 'generic_decline' ? t('WE_DO_NOT_ACCEPT_PREPAID_CARDS') : error.message;
                    setMessage(errorMessage);
                    dispatch(addToast({ error: true, text: errorMessage }));
                  } else {
                    const errorMessage =
                      'Subscription created successfully but error while submitting form, please try again.';
                    setMessage(errorMessage);
                    dispatch(addToast({ error: true, text: errorMessage }));
                  }
                  setIsLoading(false);
                }
              })
              .catch(async error => {
                const { code, response } = error || {};
                if (code === 'ECONNABORTED' || code === 'ERR_NETWORK') {
                  const errorText =
                    'Failed to create subscription due to a poor network connection, please connect to a better network and try again.';
                  dispatch(addToast({ error: true, text: errorText }));
                  setMessage(errorText);
                  setIsLoading(false);
                  return;
                }
                const { data } = response || {};
                const { error_description } = data || {};
                if (error_description === 'Matching subscription already exists for the contact') {
                  await onSubmitForm(contactData.id, subs?.external_reference, subs?.id, false, setMessage);
                } else {
                  dispatch(addToast({ error: true, text: error_description }));
                  setMessage(error_description);
                }
                setIsLoading(false);
              });
          })
          .catch(error => {
            setIsLoading(false);
            const { code, response } = error || {};
            if (code === 'ECONNABORTED' || code === 'ERR_NETWORK') {
              const errorText =
                'Failed to create payment method due to a poor network connection, please connect to a better network and try again.';
              dispatch(addToast({ error: true, text: errorText }));
              setMessage(errorText);
              setIsLoading(false);
              return;
            }
            const { data } = response || {};
            const { error_description } = data || {};
            dispatch(addToast({ error: true, text: error_description }));
            setMessage(error_description);
            setIsLoading(false);
          });
      })
      .catch(error => {
        setIsLoading(false);
        const { code, response } = error || {};
        if (code === 'ECONNABORTED' || code === 'ERR_NETWORK') {
          const errorText =
            'Failed to create contact due to a poor network connection, please connect to a better network and try again.';
          dispatch(addToast({ error: true, text: errorText }));
          setMessage(errorText);
          setIsLoading(false);
          return;
        }
        const { data } = response || {};
        const { error_description } = data || {};
        dispatch(addToast({ error: true, text: error_description }));
        setMessage(error_description);
        setIsLoading(false);
      });
  };

  const paymentElementOptions = {
    layout: 'tabs',
    fields: {
      billingDetails: {
        address: {
          country: selectedAddress?.country_code ? 'never' : 'auto',
        },
      },
    },
    defaultValues: {
      billingDetails: getUserStripeBillingDetails(),
    },
  };

  return (
    <div className="w-full">
      <label className="regular-text lighter-text">Enter payment details</label>
      <form className="mt-4 justify-center items-center">
        <PaymentElement
          options={paymentElementOptions}
          onChange={event => {
            const { complete, empty } = event;
            if (!empty && complete) {
              Idempotency_Key.current = generateNewIdempotencyKey({
                contacts: { ...Idempotency_Key.current.contacts },
              });
            }
          }}
        />
        <Button
          label={`Donate ${subscription?.priceAmountLabel ? subscription.priceAmountLabel : ''}`}
          className={`w-full mt-6 ${(isLoading || !stripe || !elements) && 'disabled'}`}
          disabled={isLoading || !stripe || !elements}
          onClick={onSubmit}
          size="large"
          borderRadius="16px"
          borderColor={backgroundColor || '#8927EF'}
          bgColor={backgroundColor || '#8927EF'}
          color="#ffffff"
          width="100%"
        />
        {paymentConfirmationMessage && (
          <div className="regular-text green-positive-text mt-4">{paymentConfirmationMessage}</div>
        )}
        {message && <div className="regular-text error-text mt-4">{message}</div>}
      </form>
    </div>
  );
};

const StripeWrapper = ({
  backgroundColor = '',
  subscription,
  setPaymentDetails,
  onSubmitForm,
  selectedAddress,
  productComponentName,
  integration,
  api_key,
  font,
  fontFamily,
  selectedBillingAddress,
  showBillingAddress,
}) => {
  const stripePromise = loadStripe(integration.public_key);

  const appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: backgroundColor || '#8927EF',
      colorBackground: '#ffffff',
      colorText: '#16192C',
      spacingUnit: '4px',
      borderRadius: '16px',
      fontFamily: fontFamily?.fontFamily || 'inherit',
    },
  };

  const option = {
    mode: 'subscription',
    amount: subscription?.amount || 1000,
    currency: subscription?.currency?.toLowerCase() || 'usd',
    paymentMethodCreation: 'manual',
    setupFutureUsage: 'off_session',
    appearance,
    fonts: [
      {
        cssSrc:
          'https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
      },
      {
        cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap',
      },
    ],
  };

  return (
    <Elements stripe={stripePromise} options={option}>
      <StripePayment
        backgroundColor={backgroundColor}
        subscription={subscription}
        setPaymentDetails={setPaymentDetails}
        onSubmitForm={onSubmitForm}
        selectedAddress={selectedAddress}
        selectedBillingAddress={selectedBillingAddress}
        productComponentName={productComponentName}
        integration={integration}
        api_key={api_key}
        showBillingAddress={showBillingAddress}
      />
    </Elements>
  );
};

export default StripeWrapper;
